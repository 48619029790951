import { Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import timelineImg1 from '../../images/aboutUs-img1.svg';
import timelineImg2 from '../../images/aboutUs-img2.svg';
import timelineImg3 from '../../images/aboutUs-img3.svg';
import timelineImg4 from '../../images/aboutUs-img4.svg';

const TimeLine = () => {
  const timeline = useMemo(
    () => [
      {
        date: '2023.01',
        content: (
          <span>
            1월 서초물류센터 리뉴얼 오픈 <br /> 기업부설연구소 설립
          </span>
        )
      },
      { date: '2022.11', content: <span>트러스테이 MOU 체결</span> },
      { date: '2022.08', content: <span>Pre-A 투자 유치</span> },
      { date: '2022.07', content: <span>KB국민카드 Future9 9기 선정</span> },
      {
        date: '2022.06',
        content: <span>호텔 룸서비스 무인 로봇 서빙 시스템 개발</span>
      },
      {
        date: '2022.05',
        content: <span>그랑자이 신촌 커뮤니티센터 입점</span>
      },
      {
        date: '2022.03',
        content: <span>사내카페 구축 서비스 런칭, 코인원 입점</span>
      },
      {
        date: '2022.02',
        content: <span>WH 냉장쇼케이스(실내전용) 모델 개발</span>
      },
      { date: '2021.12', content: <span>롯데호텔 계약 체결</span> },
      {
        date: '2021.10',
        content: <span>WH 냉장쇼케이스(실내전용) 모델 개발</span>
      },
      {
        date: '2021.08',
        content: (
          <span>
            야놀자 계약 체결 <br />
            롯데벤처스 L-Camp 선정
          </span>
        )
      },
      {
        date: '2021.07',
        content: (
          <span>
            WH 키오스크 솔루션 개발 <br />
            벤처인증 획득
          </span>
        )
      },
      {
        date: '2021.06',
        content: (
          <span>
            2021년 6월 롯데그룹 사내벤처로 spin off <br />
            2021년 6월 seed 투자 유치 <br />
            위워크 코리아 국내 전지점 계약 체결
          </span>
        )
      }
    ],
    []
  );
  const timelineImg = useMemo(
    () => [
      {
        img: timelineImg1,
        title: '누적 회원수',
        content: (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography sx={{ fontSize: { md: 20, xs: 14 } }}>약</Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 70, xs: 32 } }}
            >
              1.7
            </Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 24, xs: 14 } }}
            >
              만명
            </Typography>
          </Stack>
        )
      },
      {
        img: timelineImg2,
        title: '월 이용건수',
        content: (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 70, xs: 32 } }}
            >
              10
            </Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 24, xs: 14 } }}
            >
              만건
            </Typography>
          </Stack>
        )
      },
      {
        img: timelineImg3,
        title: '누적 회원수',
        content: (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 70, xs: 32 } }}
            >
              95
            </Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 24, xs: 14 } }}
            >
              %
            </Typography>
          </Stack>
        )
      },
      {
        img: timelineImg4,
        title: '누적 회원수',
        content: (
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography sx={{ fontSize: { md: 20, xs: 14 } }}>약</Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 70, xs: 32 } }}
            >
              90
            </Typography>
            <Typography
              color="primary"
              sx={{ fontFamily: 'Poppins', fontSize: { md: 24, xs: 14 } }}
            >
              %
            </Typography>
          </Stack>
        )
      }
    ],
    []
  );
  return (
    <Stack
      sx={{
        backgroundColor: '#F5F5F5',
        mt: {
          md: '100px',
          xs: '50px'
        },
        p: { md: '60px 10px', xs: '50px 0' }
      }}
    >
      <Stack
        sx={{
          maxWidth: '1260px',
          margin: 'auto',
          width: '88%'
        }}
      >
        <Stack
          sx={{
            flexDirection: {
              md: 'row',
              xs: 'column'
            },
            gap: 2
          }}
        >
          <Stack sx={{ width: { md: '40%', xs: '100%' } }}>
            <Typography
              sx={{ fontSize: { md: 32, xs: 20 }, fontWeight: 'bold' }}
            >
              워커스하이는 <br /> 지속 성장 중입니다.
            </Typography>
          </Stack>
          <Stack sx={{ width: { md: '60%', xs: '100%' } }}>
            {timeline.map(({ date, content }) => (
              <Stack
                flexDirection="row"
                sx={{ gap: { md: 9, xs: 5 }, mb: { md: 5, xs: 2 } }}
              >
                <Typography
                  color="primary"
                  sx={{ fontSize: { md: 24, xs: 12 }, fontWeight: 'bold' }}
                >
                  {date}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: 20, xs: 12 }, letterSpacing: '0.3px' }}
                  fontWeight={{ md: 500, xs: 400 }}
                >
                  {content}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          sx={{ mt: { md: '80px', xs: '40px' } }}
        >
          {timelineImg.map((item, index) => (
            <TimeLineCard
              {...item}
              sx={{
                p: {
                  md:
                    (index === 1 && '0 0 15px 15px') ||
                    (index === 2 && '15px 15px 0 0') ||
                    (index === 3 && '15px 0 0 15px') ||
                    '0 15px 15px 0',
                  xs:
                    (index === 1 && '0 0 10px 10px') ||
                    (index === 2 && '10px 10px 0 0') ||
                    (index === 3 && '10px 0 0 10px') ||
                    '0 10px 10px 0'
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const TimeLineCard = ({ title, content, img, sx }) => {
  return (
    <Stack sx={{ width: '50%', ...sx }}>
      <Stack
        sx={{
          flexDirection: { md: 'row', xs: 'column' },
          borderRadius: { md: '30px', xs: '15px' },
          p: { md: '30px' },
          gap: { md: 5, xs: 2 },
          height: { md: 210, xs: 180 }
        }}
        bgcolor="white"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { md: '50%', xs: '100%' },
            justifyContent: { md: 'flex-end', xs: 'center' }
          }}
          flexDirection="row"
        >
          <img src={img} alt="img" className="timeline-card" />
        </Stack>
        <Stack
          sx={{
            width: { md: '50%', xs: '100%' },
            justifyContent: { md: 'flex-start', xs: 'center' }
          }}
          flexDirection="row"
        >
          <div>
            <Typography
              sx={{ fontSize: { md: 28, xs: 14 }, fontWeight: 'bold' }}
              textAlign={{ md: 'left', xs: 'center' }}
            >
              {title}
            </Typography>
            <Typography>{content}</Typography>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TimeLine;
