import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { ReactChannelIO } from 'react-channel-plugin';
import Globals from '_Globals';
import App from './App';
import store from './store';
import ThemeConfig from './theme';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const AppRender = () => {
  return (
    <ReactChannelIO
      pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
      language="ko"
      autoBoot
    >
      <App />
      <Globals />
    </ReactChannelIO>
  );
};
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta
          name="title"
          content="사내 스낵바, 호텔 스토어, 격이 다른 공간 리테일 서비스, 워커스하이"
        />
        <meta
          name="description"
          content="비스포크 맞춤 제작 방식과 IT 및 데이터 기반으로 최상의 고객들에게 서비스를 제공합니다. No.1 대기업, 글로벌 기업에서 사내 스낵바를 운영하고 있으며, 국내외 특급호텔에서 라운지 스토어를 운영하고 있습니다. Next Retail의 핵심은 바로 실내 공간! 기술로 리테일을 혁신합니다."
        />
      </Helmet>
      <Provider store={store}>
        <ThemeConfig>
          <AppRender />
        </ThemeConfig>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
