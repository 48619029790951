import { Button, Hidden, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import logo from 'assets/images/logo_bk.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@components/modals';
import btnMini from 'images/icon/icon-menu-bl.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useChannelIOApi, useChannelIOEvent } from 'react-channel-plugin';
import { setInquiry } from '@store/reducers/appReducer';
import ServiceInquiry from './components/ServiceInquiry';
import ConsultationInquiryForm from './components/ConsultationInquiryForm';

const titles = ['서비스 문의', '무료 상담 신청', ''];

const NavBar = () => {
  const navigate = useNavigate();
  const openInquiry = useSelector((state) => state.app.openInquiry);
  const dispatch = useDispatch();
  const location = useLocation();
  const navList = useMemo(
    () => [
      { title: 'About us', href: '/' },
      { title: 'Offresh', href: '/offresh' }
      // { title: '도입 사례', href: '/' },
      // { title: 'FAQ', href: '/' }
    ],
    []
  );
  const { showMessenger } = useChannelIOApi();

  useChannelIOEvent('onShowMessenger', () => {
    setOpen(false);
  });
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const [openMo, setOpenMo] = useState(false);
  const [popupSelected, setPopupSelected] = useState(0);
  const handleSelectPopup = (idx) => {
    if (idx === 1) {
      setPopupSelected(1);
      return;
    }
    if (idx === 3) {
      showMessenger();
      return;
    }
    if (idx === 4) {
      setOpenMo(!openMo);
      return;
    }
    if (location?.pathname?.includes('offresh')) {
      setOpen(false);
    }
    setTimeout(() => {
      setPopupSelected(0);
      setSelected(0);
      dispatch(setInquiry(false));
    }, 200);
  };

  useEffect(() => {
    if (openInquiry) {
      setPopupSelected(1);
      setOpen(true);
    }
  }, [openInquiry]);
  return (
    <Stack
      sx={{
        maxWidth: '1920px',
        margin: 'auto',
        width: '100%',
        left: '50%',
        position: !location?.pathname?.includes('offresh') && 'fixed',
        transform:
          !location?.pathname?.includes('offresh') && 'translate(-50%, 0%)',
        zIndex: 1000
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'white',
          // position: !location?.pathname?.includes('offresh') && 'fixed',
          width: '100%',
          top: 0,
          p: {
            md: '30px 60px',
            sm: '15px 40px',
            xs: '10px 20px'
          }
        }}
      >
        <Stack sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <Hidden mdDown>
            <img src={logo} alt="logo" width={200} />
          </Hidden>
          <Hidden mdUp>
            <img src={logo} alt="logo" width={135} height={22} />
          </Hidden>
        </Stack>
        <Hidden mdDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={8}
          >
            {navList.map(({ title, href }) => (
              <Typography
                fontSize={20}
                fontWeight={500}
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(href)}
              >
                {title}
              </Typography>
            ))}
          </Stack>
          <Button
            variant="contained"
            sx={{ boxShadow: 0, width: 120, fontSize: 15 }}
            onClick={() => setOpen(true)}
          >
            서비스 문의
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Button
              variant="contained"
              sx={{ boxShadow: 0, width: 80, height: 33, fontSize: 11 }}
              onClick={() => setOpen(true)}
            >
              서비스 문의
            </Button>
            <Stack onClick={() => handleSelectPopup(4)}>
              <img src={btnMini} alt="btn" />
            </Stack>
          </Stack>
        </Hidden>
      </Stack>
      <Modal
        open={open}
        setOpen={setOpen}
        title={titles[selected]}
        hasCloseButton
        onClose={() => handleSelectPopup(0)}
      >
        {popupSelected === 0 && (
          <ServiceInquiry
            selected={selected}
            setSelected={setSelected}
            onSelected={handleSelectPopup}
          />
        )}
        {popupSelected === 1 && (
          <ConsultationInquiryForm
            turnBack={() => {
              setPopupSelected(0);
              setSelected(0);
              dispatch(setInquiry(false));
            }}
            closeModal={() => setOpen(false)}
          />
        )}
      </Modal>
      <Modal
        open={openMo}
        setOpen={setOpenMo}
        title={null}
        sx={{
          dialogContent: {
            p: 0
          }
        }}
      >
        <MenuMobile setOpen={setOpen} setOpenMo={setOpenMo} />
      </Modal>
    </Stack>
  );
};

const MenuMobile = ({ setOpen, setOpenMo }) => {
  const navigate = useNavigate();
  return (
    <Stack height="100%" bgcolor="white">
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'white',
          width: '100%',
          top: 0,
          p: {
            md: '30px 60px',
            sm: '15px 40px',
            xs: '10px 20px'
          },
          zIndex: 1000
        }}
      >
        <Stack sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img src={logo} alt="logo" width={135} height={22} />
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Button
            variant="contained"
            sx={{ boxShadow: 0, width: 80, height: 33, fontSize: 11 }}
            onClick={() => {
              setOpenMo(false);
              setOpen(true);
            }}
          >
            서비스 문의
          </Button>
          <Stack onClick={() => setOpenMo(false)}>
            <img src={btnMini} alt="btn" />
          </Stack>
        </Stack>
      </Stack>
      <Stack flexDirection="column" alignItems="center" gap={2} mt="40px">
        <Typography
          sx={{ cursor: 'pointer' }}
          fontWeight={500}
          onClick={() => {
            navigate('/');
            setOpenMo(false);
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{ cursor: 'pointer' }}
          fontWeight={500}
          onClick={() => {
            navigate('/offresh');
            setOpenMo(false);
          }}
        >
          Offresh
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NavBar;
