import { Stack, Typography } from '@mui/material';
import React from 'react';
import app1 from 'images/prosess-img1.svg';
import app2 from 'images/prosess-img2.svg';
import app3 from 'images/prosess-img3.svg';
import app4 from 'images/prosess-img4.svg';
import app5 from 'images/prosess-img5.svg';
import app6 from 'images/prosess-img6.svg';
import ApplicationCard from '@components/cards/ApplicationCard';

const Application = ({ id, inViewport }) => {
  const appList = [
    { id: '01', src: app1, content: <span>서비스 신청서 작성</span> },
    { id: '02', src: app2, content: <span>담당자 배정 및 조건 협의</span> },
    {
      id: '03',
      src: app3,
      content: <span>전담 매니저 방문 후 공간 실측</span>
    },
    { id: '04', src: app4, content: <span>계약 체결</span> },
    {
      id: '05',
      src: app5,
      content: (
        <span>
          입점 준비
          <br /> (상품 큐레이션, 설비 제작)
        </span>
      )
    },
    { id: '06', src: app6, content: <span>입점 완료 및 서비스 오픈</span> }
  ];
  return (
    <Stack
      id={id}
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        p: {
          md: '50px 0 100px 0',
          xs: '25px 0 60px 0'
        },
        width: '88%'
      }}
      className={`scroll-component ${inViewport ? 'in-viewport' : ''}`}
    >
      <Typography
        textAlign="center"
        sx={{
          fontSize: { md: 48, xs: 24 },
          fontWeight: 'bold',
          mb: {
            md: '60px',
            xs: '50px'
          }
        }}
      >
        신청 프로세스
      </Typography>
      <Stack
        flexDirection="row"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
      >
        {appList.map(({ src, content, id }) => (
          <Stack
            sx={{ width: { md: '33%', xs: '50%' } }}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <ApplicationCard src={src} content={content} id={id} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Application;
