import { Stack, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '120px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '70px'
    }
  },
  container: {
    '&:hover': {
      backgroundColor: '#4267EB',
      cursor: 'pointer',
      color: 'white',
      '& .desc, .detail': {
        color: 'white'
      }
    }
  }
}));

const ServiceInquiryCard = ({
  src,
  title,
  desc,
  detail,
  onClick,
  selected = false
}) => {
  const classes = useStyles();
  return (
    <Stack
      className={classes.container}
      sx={{
        flexDirection: { md: 'column', xs: 'row' },
        bgcolor: !selected ? '#F5F5F5' : '#4267EB',
        p: { md: '30px 10px', xs: '30px 17px' },
        height: { md: '350px' },
        borderRadius: { md: '30px', xs: '18px' },
        width: '100%',
        gap: { md: '10px', xs: '17px' }
      }}
      onClick={onClick}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="center">
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Stack flexDirection="column" sx={{ gap: { md: 1, xs: 0.6 } }}>
        <Typography
          sx={{
            fontSize: { md: 24, xs: 18 },
            fontWeight: 'bold',
            textAlign: { md: 'center', xs: 'left' },
            color: selected && 'white'
          }}
        >
          {title}
        </Typography>
        <Typography
          className="desc"
          sx={{
            fontSize: { md: 16, xs: 13 },
            color: !selected ? '#4267EB' : 'white',
            textAlign: { md: 'center', xs: 'left' }
          }}
        >
          {desc}
        </Typography>
        <Typography
          className="detail"
          sx={{
            fontSize: { md: 16, xs: 13 },
            color: selected ? 'white' : '#555',
            textAlign: { md: 'center', xs: 'left' }
          }}
        >
          {detail}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ServiceInquiryCard;
