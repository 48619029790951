import { Stack, Typography } from '@mui/material';
import React from 'react';

const ServiceCard = (props) => {
  const {
    bgColor = 'white',
    title,
    titleColor,
    desc,
    descColor,
    button,
    sx
  } = props;
  return (
    <Stack
      flexDirection="column"
      bgcolor={bgColor}
      sx={{
        height: { md: '300px', xs: '200px' },
        borderRadius: { md: '30px', xs: '15px' },
        p: { md: '10px 42px 42px 60px', xs: '10px 32px' },
        ...sx
      }}
    >
      <Typography color={titleColor} sx={{ fontSize: { md: 96, xs: 40 } }}>
        {title}
      </Typography>
      <Typography color={descColor} sx={{ fontSize: { md: 20, xs: 14 } }}>
        {desc}
      </Typography>
      <Stack
        sx={{
          height: '50%',
          flexDirection: 'row',
          justifyContent: {
            md: 'flex-end',
            xs: titleColor !== 'white' ? 'flex-end' : 'flex-start'
          },
          alignItems: 'flex-end'
        }}
      >
        {button}
      </Stack>
    </Stack>
  );
};

export default ServiceCard;
