import { Hidden, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import OffreshLogo from 'images/offresh_bk.svg';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    width: 'auto',
    height: '54px',
    [theme.breakpoints.down('md')]: {
      height: '28px'
    }
  }
}));
const Menu = ({ onClick, idxNav }) => {
  const classes = useStyles();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const winST = window.pageYOffset || document.documentElement.scrollTop;
      const tablistST = document.getElementById('page_tab').offsetTop;

      if (winST >= tablistST) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Stack sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Stack>
        <img className={classes.img} src={OffreshLogo} alt="logo" />
      </Stack>
      <Stack
        textAlign="center"
        sx={{ m: { md: '40px 0 40px 0', xs: '15px 0 20px 0' } }}
      >
        <Typography sx={{ fontSize: { md: 24, xs: 14 } }} letterSpacing="1px">
          임직원을 위한 맞춤형,{' '}
          <Hidden mdUp>
            <br />
          </Hidden>{' '}
          토탈 오피스 라이프스타일 솔루션
        </Typography>
      </Stack>
      <Stack width="98%" id="page_tab" className={isSticky ? 'on' : ''}>
        <Stack
          id="menu"
          className="tab_list"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: { md: '120px', xs: '60px' },
            width: '98%'
          }}
          gap={{ md: 20, xs: 3 }}
          borderBottom="1px solid gray"
        >
          <Stack onClick={() => onClick(1)}>
            <Typography
              sx={{
                fontSize: { md: 28, xs: 16 },
                color: idxNav === 0 ? '#111' : '#9C9C9C',
                fontWeight: 500,
                '&:hover': {
                  color: '#111',
                  cursor: 'pointer'
                }
              }}
            >
              서비스 소개
            </Typography>
          </Stack>
          <Stack onClick={() => onClick(2)}>
            <Typography
              sx={{
                fontSize: { md: 28, xs: 16 },
                color: idxNav === 1 ? '#111' : '#9C9C9C',
                fontWeight: 500,
                '&:hover': {
                  color: '#111',
                  cursor: 'pointer'
                }
              }}
            >
              서비스 타입
            </Typography>
          </Stack>
          <Stack onClick={() => onClick(3)}>
            <Typography
              sx={{
                fontSize: { md: 28, xs: 16 },
                color: idxNav === 2 ? '#111' : '#9C9C9C',
                fontWeight: 500,
                '&:hover': {
                  color: '#111',
                  cursor: 'pointer'
                }
              }}
            >
              신청 프로세스
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Menu;
