/* eslint-disable import/no-duplicates */
import Marquee from 'react-fast-marquee';
import React, { useState } from 'react';
import { Button, Hidden, Stack, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './Service.css';
import './Marquee.css';
import { Pagination } from 'swiper';

import img1 from 'images/product_item/item18.png';
import img2 from 'images/product_item/item19.png';
import img3 from 'images/product_item/item4.png';
import img4 from 'images/product_item/item11.png';
import img5 from 'images/product_item/item20.png';
import img6 from 'images/product_item/item21.png';
import img7 from 'images/product_item/item9.png';
import img8 from 'images/product_item/item10.png';
import img9 from 'images/product_item/item17.png';
import img10 from 'images/product_item/item22.png';
import img11 from 'images/product_item/item18.png';
import img12 from 'images/product_item/item19.png';
import img13 from 'images/product_item/item4.png';
import img14 from 'images/product_item/item11.png';
import img15 from 'images/product_item/item20.png';
import img16 from 'images/product_item/item21.png';

import img111 from 'images/product_item/item20.png';
import img121 from 'images/product_item/item21.png';
import img21 from 'images/product_item/item5.png';
import img22 from 'images/product_item/item10.png';
import img23 from 'images/product_item/item13.png';
import img31 from 'images/product_item/item22.png';
import img41 from 'images/product_item/item17.png';
import img51 from 'images/product_item/item23.png';
import img52 from 'images/product_item/item7.png';
import img53 from 'images/product_item/item18.png';
import img54 from 'images/product_item/item19.png';
import img61 from 'images/product_item/item3.png';
import img62 from 'images/product_item/item24.png';
import img63 from 'images/product_item/item4.png';
import img64 from 'images/product_item/item11.png';
import Modal from '@components/modals';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '420px',
    [theme.breakpoints.down('md')]: {
      height: '150px'
    }
  },
  imgPopup: {
    height: '280px',
    width: '280px',
    [theme.breakpoints.down('sm')]: {
      height: '200px !important',
      width: '200px !important'
    }
  }
}));

const listItems = [
  { src: img1, title: '프리미엄-중형' },
  { src: img2, title: '프리미엄-대형' },
  { src: img3, title: '프리미엄-중형' },
  { src: img4, title: '비스포크-제작' },
  { src: img5, title: '키오스크(10inch)' },
  { src: img6, title: '키오스크(32inch)' },
  { src: img7, title: '비스포크-1door' },
  { src: img8, title: '비스포크-2door' },
  { src: img9, title: '일반/주류겸용' },
  { src: img10, title: 'Ai_일반(4단)' },
  { src: img11, title: '프리미엄-중형' },
  { src: img12, title: '프리미엄-대형' },
  { src: img13, title: '프리미엄-중형' },
  { src: img14, title: '비스포크-제작' },
  { src: img15, title: '키오스크(10inch)' },
  { src: img16, title: '키오스크(32inch)' }
];
export const MarqueeComponent = () => {
  const [open, setOpen] = useState(false);
  return (
    <Stack
      bgcolor="#f5f5f5"
      m={{ md: '100px 0 0 0', xs: '40px 0 0 0' }}
      p="40px 0"
    >
      <Stack maxWidth="100%" margin="auto">
        <Marquee speed={120} pauseOnHover style={{ maxWidth: '1920px' }}>
          {listItems.map(({ src, title }) => (
            <ItemComponent src={src} title={title} />
          ))}
        </Marquee>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{
            width: { md: '200px', xs: '180px' },
            height: { md: '45px', xs: '40px' },
            fontSize: { md: 16, xs: 14 },
            m: { md: '40px auto 10px auto', xs: '20px auto 0 auto' },
            letterSpacing: '0.5px'
          }}
        >
          상품 라인업 더 알아보기
        </Button>
      </Stack>
      {open && (
        <Modal
          sx={{
            maxWidth: { md: '1240px', xs: '100%' },
            width: { md: '1240px', xs: '100%' },
            dialogContent: {
              p: 0
            }
          }}
          open={open}
          setOpen={setOpen}
          hasCloseButton
          title={
            <Typography
              fontFamily="Poppins"
              fontSize={{ md: 26, xs: 16 }}
              fontWeight={500}
            >
              PRODUCT LINE UP
            </Typography>
          }
        >
          <PopupComponent />
        </Modal>
      )}
    </Stack>
  );
};

const ItemComponent = ({ src, title }) => {
  const classes = useStyles();
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="center"
    >
      <Stack
        sx={{
          height: { md: '420px', xs: '150px' },
          m: { md: '0 40px', xs: '0' }
        }}
      >
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Typography
        mt={{ md: '30px', xs: '12px' }}
        fontSize={{ md: 24, xs: 14 }}
        fontWeight={500}
        letterSpacing="0.5px"
      >
        {title}
      </Typography>
    </Stack>
  );
};

const listPopupProducts = [
  {
    src: img111,
    title: '키오스크 (10inch)',
    listDetail: [
      { key: '규격', content: 'W450*H400*D400' },
      { key: '결제옵션', content: '사원증, 복지포인트, 신용카드, 간편결제' },
      { key: '색상', content: '블랙' }
    ],
    colors: ['#111111']
  },
  {
    src: img121,
    title: '키오스크 (32inch)',
    listDetail: [
      { key: '규격', content: 'W800*D450*H1800' },
      { key: '결제옵션', content: '사원증, 복지포인트, 신용카드, 간편결제' },
      { key: '색상', content: '블랙' }
    ],
    colors: ['#111111']
  },
  {
    src: img21,
    title: '비스포크-1door',
    listDetail: [
      { key: '규격', content: 'W1430*D700*2230' },
      { key: '추천예산', content: '3백만원' },
      { key: '결제옵션', content: '사원증, 복지포인트, 신용카드, 간편결제' },
      { key: '색상', content: '블랙/화이트/우드' }
    ],
    colors: ['#111', '#f5f5f5', '#907D66']
  },
  {
    src: img22,
    title: '비스포크-2door',
    listDetail: [
      { key: '규격', content: 'W1900*D700*2230' },
      { key: '추천예산', content: '5백만원' },
      { key: '결제옵션', content: '사원증, 복지포인트, 신용카드, 간편결제' },
      { key: '색상', content: '블랙/화이트/우드' }
    ],
    colors: ['#111', '#f5f5f5', '#907D66']
  },
  {
    src: img23,
    title: '비스포크-3door',
    listDetail: [
      { key: '규격', content: 'W2720*D700*2230' },
      { key: '추천예산', content: '7백만원' },
      { key: '결제옵션', content: '사원증, 복지포인트, 신용카드,간편결제' },
      { key: '색상', content: '블랙/화이트/우드' }
    ],
    colors: ['#111', '#f5f5f5', '#907D66']
  },
  {
    src: img31,
    title: 'Ai_일반/주류 (4단)',
    listDetail: [
      { key: '규격', content: 'W900*D790*H2004' },
      { key: '추천예산', content: '3-4백만원' },
      { key: '색상', content: '블랙/화이트/그레이' }
    ],
    colors: ['#111', '#f5f5f5', '#a0a0a0']
  },
  {
    src: img31,
    title: 'Ai_일반/주류 (6단)',
    listDetail: [
      { key: '규격', content: 'W969*D790*H2261' },
      { key: '추천예산', content: '4-5백만원' },
      { key: '색상', content: '블랙/화이트/그레이' }
    ],
    colors: ['#111', '#f5f5f5', '#a0a0a0']
  },
  {
    src: img41,
    title: '일반/주류겸용',
    listDetail: [
      { key: '규격', content: 'W2420*D825*H1950' },
      { key: '추천예산', content: '7백만원' },
      { key: '색상', content: '화이트/블랙' }
    ],
    colors: ['#f5f5f5', '#111']
  },
  {
    src: img51,
    title: '스탠다드-소형',
    listDetail: [
      { key: '규격', content: 'W63.5*D30*80.5' },
      { key: '추천예산', content: '10만원' },
      { key: '색상', content: '기성품 (변경가능)' }
    ],
    colors: ['#f5f5f5', '#907D66', '#111']
  },
  {
    src: img52,
    title: '스탠다드-중형',
    listDetail: [
      { key: '규격', content: 'W700*D350*H1400' },
      { key: '추천예산', content: '30만원' },
      { key: '색상', content: '철제(회색)' }
    ],
    colors: ['#767676']
  },
  {
    src: img53,
    title: '프리미엄-중형',
    listDetail: [
      { key: '규격', content: 'W700*D350*H1800' },
      { key: '추천예산', content: '50만원' },
      { key: '색상', content: '블랙/화이트/우드' }
    ],
    colors: ['#f5f5f5', '#907D66', '#111']
  },
  {
    src: img54,
    title: '프리미엄-대형',
    listDetail: [
      { key: '규격', content: 'W800*D450*H1800' },
      { key: '추천예산', content: '70만원' },
      { key: '색상', content: '블랙/화이트/우드' }
    ],
    colors: ['#f5f5f5', '#907D66', '#111']
  },
  {
    src: img61,
    title: '스탠다드-소형',
    listDetail: [
      { key: '규격', content: 'W523*D53*H83.5cm' },
      { key: '추천예산', content: '10만원' },
      { key: '색상', content: '블랙/화이트' }
    ],
    colors: ['#111', '#f5f5f5']
  },
  {
    src: img62,
    title: '스탠다드-중형',
    listDetail: [
      { key: '규격', content: 'W650*D600*H1900' },
      { key: '추천예산', content: '70만원' },
      { key: '색상', content: '블랙/화이트' }
    ],
    colors: ['#111', '#f5f5f5']
  },
  {
    src: img63,
    title: '프리미엄-중형',
    listDetail: [
      { key: '규격', content: 'W650*D600*H1900' },
      { key: '추천예산', content: '50만원' },
      { key: '색상', content: '블랙/화이트' }
    ],
    colors: ['#111', '#f5f5f5']
  },
  {
    src: img64,
    title: '비스포크-제작',
    listDetail: [
      { key: '규격', content: '실측 후 제작' },
      { key: '추천예산', content: '제작 규모 따른 변동' },
      { key: '색상', content: '스테인리스(실버)' }
    ],
    colors: ['#a0a0a0']
  }
];

const PopupComponent = () => {
  const item1 = listPopupProducts.slice(0, 2);
  const item2 = listPopupProducts.slice(2, 5);
  const item3 = listPopupProducts.slice(5, 7);
  const item4 = listPopupProducts.slice(7, 8);
  const item5 = listPopupProducts.slice(8, 12);
  const item6 = listPopupProducts.slice(12);
  return (
    <Stack
      sx={{ width: '85%', margin: 'auto', mt: { sm: '92px', xs: '40px' } }}
    >
      <Stack>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          키오스크
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap="4%"
          >
            {item1.map(({ src, title, listDetail, colors }) => (
              <Stack width="48%">
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <SwiperComponent items={item1} />
        </Hidden>
      </Stack>
      <Stack>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          mt={{ sm: '30px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          비스포크 (셀프결제형)
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap={{ md: '2%', xs: '4%' }}
          >
            {item2.map(({ src, title, listDetail, colors }) => (
              <Stack width={{ md: '32%', xs: '48%' }}>
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <SwiperComponent items={item2} />
        </Hidden>
      </Stack>
      <Stack>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          mt={{ sm: '30px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          Ai 벤딩머신
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap="4%"
          >
            {item3.map(({ src, title, listDetail, colors }) => (
              <Stack width="48%">
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <SwiperComponent items={item3} />
        </Hidden>
      </Stack>
      <Stack>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          mt={{ sm: '30px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          스마트 벤딩머신
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap="4%"
          >
            {item4.map(({ src, title, listDetail, colors }) => (
              <Stack width="48%">
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <SwiperComponent items={item4} />
        </Hidden>
      </Stack>
      <Stack>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          mt={{ sm: '30px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          상온장
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap="4%"
          >
            {item5.map(({ src, title, listDetail, colors }) => (
              <Stack width="48%">
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <SwiperComponent items={item5} />
        </Hidden>
      </Stack>
      <Stack mb={{ md: '40px', xs: '70px' }}>
        <Typography
          fontSize={{ sm: 40, xs: 23 }}
          fontWeight={700}
          mb={{ sm: '70px', xs: '20px' }}
          mt={{ sm: '30px', xs: '20px' }}
          textAlign={{ sm: 'left', xs: 'center' }}
        >
          냉장 쇼케이스
        </Typography>
        <Hidden smDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
            gap="4%"
          >
            {item6.map(({ src, title, listDetail, colors }) => (
              <Stack width="48%">
                <ItemPopupComponent
                  src={src}
                  title={title}
                  listDetail={listDetail}
                  colors={colors}
                />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden smUp>
          <Stack>
            <SwiperComponent items={item6} />
          </Stack>
        </Hidden>
      </Stack>
    </Stack>
  );
};

const ItemPopupComponent = ({ src, title, listDetail, colors }) => {
  const classes = useStyles();
  return (
    <Stack flexDirection="column" width="100%" mb={{ sm: '70px', xs: '24px' }}>
      <Stack
        bgcolor="#f5f5f5"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: { sm: '30px', xs: '15px' },
          height: { sm: '340px', xs: '270px' }
        }}
      >
        <img className={classes.imgPopup} src={src} alt="img" />
      </Stack>
      <Typography
        fontSize={{ sm: 28, xs: 16 }}
        fontWeight={700}
        m={{ sm: '30px 0 10px 0', xs: '20px 0 7px 0' }}
        textAlign="left"
      >
        {title}
      </Typography>
      {listDetail.map(({ key, content }) => (
        <Stack flexDirection="row" alignItems="baseline" m="2px 0">
          <Typography
            color="#8B8B8B"
            fontSize={{ sm: 20, xs: 13 }}
            width={{ sm: '100px', xs: '70px' }}
            flexShrink={0}
            textAlign="left"
          >
            {key}
          </Typography>
          <Typography fontSize={{ sm: 20, xs: 13 }}>{content}</Typography>
        </Stack>
      ))}
      <Stack flexDirection="row" alignItems="center">
        <Typography width={{ sm: '100px', xs: '70px' }} visibility="hidden">
          awdawd
        </Typography>
        <Stack flexDirection="row" alignItems="center">
          {colors.map((color) => (
            <Stack
              sx={{
                backgroundColor: color,
                height: { sm: '30px', xs: '17px' },
                width: { sm: '30px', xs: '17px' },
                borderRadius: { sm: '15px', xs: '8.5px' },
                mr: { sm: '20px', xs: '10px' },
                mt: { sm: '10px', xs: '5px' }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const SwiperComponent = ({ items }) => {
  return (
    <Swiper
      centeredSlides
      spaceBetween={20}
      slidesPerView="auto"
      pagination={{
        clickable: true
      }}
      className="swiper-popup-2"
      modules={[Pagination]}
    >
      {items.map(({ src, title, listDetail, colors }) => (
        <SwiperSlide className="swiper-popup-2">
          <ItemPopupComponent
            src={src}
            title={title}
            listDetail={listDetail}
            colors={colors}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
