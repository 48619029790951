import React from 'react';
import { IconButton } from '@mui/material';
import img from 'images/icon/icon-arrow-top-wh.svg';
import './ScrollTop.css';

const ScrollTop = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <IconButton
      className="scroll-top-btn"
      onClick={goToTop}
      sx={{
        position: 'fixed',
        zIndex: 1000,
        color: 'white',
        background: '#CDCDCD',
        boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.24)',
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
          background: '#555'
        }
      }}
    >
      <img src={img} alt="img" />
    </IconButton>
  );
};
export default ScrollTop;
