import React from 'react';
import { Hidden, Stack, Typography } from '@mui/material';

import img1 from 'images/icon/offresh_icon-summary1.svg';
import img2 from 'images/icon/offresh_icon-summary2.svg';
import img3 from 'images/icon/offresh_icon-summary3.svg';
import img4 from 'images/icon/offresh_icon-summary4.svg';

const Category = () => {
  return (
    <Stack
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        width: '88%'
      }}
    >
      <Stack
        sx={{
          flexDirection: {
            md: 'row',
            xs: 'column'
          },
          gap: 2
        }}
      >
        <Stack sx={{ width: { md: '40%', xs: '100%' } }}>
          <Typography sx={{ fontSize: { md: 36, xs: 20 }, fontWeight: 'bold' }}>
            <span>
              고민되고 부담되는 <br />
              스낵 복지 서비스 도입
            </span>
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          sx={{ width: { md: '60%', xs: '100%' }, flexWrap: 'wrap' }}
        >
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '0 20px 20px 0', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              bgColor="#F6FAFF"
              id="1"
              title={
                <span>
                  인원별 적정
                  <Hidden mdDown>
                    <br />
                  </Hidden>
                  예산 추정의 어려움
                </span>
              }
              src={img1}
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '0 0px 20px 20px', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              bgColor="#F5F5F5"
              id="2"
              title={
                <span>
                  100% 무료 스낵 복지의{' '}
                  <Hidden mdDown>
                    <br />
                  </Hidden>{' '}
                  예산 부담
                </span>
              }
              src={img2}
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '20px 20px 0px 0px', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              title="투박한 매대와 냉장고"
              id="3"
              sx={{ backgroundColor: { xs: '#F6FAFF', md: '#F5F5F5' } }}
              src={img3}
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '20px 0px 0px 20px' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              title="모두가 받지 못하는 혜택"
              id="4"
              sx={{ backgroundColor: { md: '#F6FAFF', xs: '#F5F5F5' } }}
              src={img4}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const IntroCard = (props) => {
  const { bgColor = 'white', title, src, id, sx } = props;
  return (
    <Stack
      flexDirection={{ md: 'column', xs: 'row' }}
      bgcolor={bgColor}
      sx={{
        justifyContent: { md: 'none', xs: 'space-between' },
        height: { md: '340px', xs: '84px' },
        borderRadius: { md: '30px', xs: '15px' },
        p: { md: 4, xs: '20px 30px' },
        ...sx
      }}
      position="relative"
    >
      <Stack>
        <Typography
          color="primary"
          sx={{ fontSize: { md: 20, xs: 12 }, letterSpacing: '0.5px' }}
          fontWeight={{ md: 700, xs: 500 }}
        >
          Pain Point 0{id}
        </Typography>
        <Typography
          color="black"
          sx={{
            fontSize: { md: 28, xs: 15 },
            fontWeight: 600,
            m: { md: '5px 0 10px 0', xs: '3px 0 7px 0' },
            letterSpacing: '0.5px'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        sx={{
          height: { md: '80px', xs: '40px' },
          width: { md: '80px', xs: '40px' },
          position: { md: 'absolute' },
          bottom: 30,
          right: 30
        }}
      >
        <img src={src} alt="img" />
      </Stack>
    </Stack>
  );
};

export default Category;
