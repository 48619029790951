import { Stack } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FeedBack from '@pages/components/FeedBack';
import React, { useEffect } from 'react';
import Partner from '@pages/components/Partner';
import Banner from './Banner';
import Category from './Category';
import './index.css';
import Service from './Service';
import TimeLine from './TimeLine';

const AboutUs = () => {
  AOS.init();
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);
  return (
    <Stack
      sx={{
        maxWidth: '100%',
        margin: 'auto',
        backgroundColor: 'white',
        pt: {
          md: '200px',
          xs: '100px'
        }
      }}
    >
      <Banner />
      <Category />
      <Service />
      <TimeLine />
      <Partner />
      <FeedBack />
    </Stack>
  );
};

export default AboutUs;
