import { Stack, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '160px',
    width: '160px',
    [theme.breakpoints.down('md')]: {
      height: '90px',
      width: '90px'
    }
  }
}));

const m1 = { md: '0 25px 25px 0', xs: '0 10px 10px 0' };
const m2 = { md: '0 25px 25px 25px', xs: '0 0 10px 10px' };
const m3 = { md: '0 0 25px 25px', xs: '10px 10px 10px 0' };
const m4 = { md: '25px 25px 0 0', xs: '10px 0 10px 10px' };
const m5 = { md: '25px 25px 0 25px', xs: '10px 10px 0 0' };
const m6 = { md: '25px 0 0 25px', xs: '10px 0 0 10px' };

const ApplicationCard = ({ id, src, content }) => {
  const classes = useStyles();
  const idxID = Number(id);
  return (
    <Stack>
      <Stack
        flexDirection="column"
        alignItems="center"
        bgcolor="#F5F5F5"
        sx={{
          borderRadius: { md: '30px', xs: '15px' },
          p: {
            md: '30px 20px',
            xs: '20px 16px'
          },
          height: { md: '330px', xs: '172px' },
          m:
            (idxID === 1 && m1) ||
            (idxID === 2 && m2) ||
            (idxID === 3 && m3) ||
            (idxID === 4 && m4) ||
            (idxID === 5 && m5) ||
            m6,
          wordBreak: 'keep-all',
          boxSizing: 'border-box'
        }}
        position="relative"
        data-aos="fade-up"
      >
        <Typography
          sx={{
            position: 'absolute',
            top: { md: 20, xs: 10 },
            left: { md: 30, xs: 15 },
            color: '#4267EB',
            fontSize: {
              md: 26,
              xs: 20
            },
            fontWeight: 500,
            fontFamily: 'Poppins'
          }}
        >
          {id}
        </Typography>
        <Stack
          height="70%"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-end"
        >
          <img className={classes.img} src={src} alt="img" />
        </Stack>
        <Stack height="30%" flexDirection="row" alignItems="center">
          <Typography
            sx={{
              fontSize: { md: 24, xs: 14 },
              mt: '10px',
              padding: '0 10px',
              letterSpacing: '0.5px'
            }}
            textAlign="center"
            fontWeight={500}
            color="#333"
          >
            {content}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApplicationCard;
