import ServiceCard from '@components/cards/ServiceCard';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

import OffreshImg from 'images/offresh_wh.svg';
import { useNavigate } from 'react-router-dom';

const Service = () => {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        width: '88%',
        mt: {
          md: '100px',
          xs: '50px'
        }
      }}
    >
      <Stack
        sx={{
          flexDirection: { md: 'row', xs: 'column' }
        }}
      >
        <Stack
          sx={{
            width: { md: '40%', xs: '100%' },
            textAlign: { xs: 'center', md: 'left' },
            mb: 2
          }}
          data-aos="fade-up"
        >
          <Typography sx={{ fontSize: { md: 32, xs: 20 }, fontWeight: 'bold' }}>
            서비스 유형
          </Typography>
        </Stack>
        <Stack
          flexDirection="column"
          sx={{ width: { md: '60%', xs: '100%' }, gap: { md: 5, xs: 2.5 } }}
        >
          <Stack data-aos="fade-up">
            <ServiceCard
              bgColor="#BBD2F5"
              title={
                <Stack
                  sx={{
                    height: { md: '80px', xs: '30px' },
                    m: { md: '40px 0 30px 0', xs: '20px 0 10px 0' }
                  }}
                  flexDirection="row"
                >
                  <img src={OffreshImg} alt="img" height="100%" width="auto" />
                </Stack>
              }
              titleColor="white"
              desc="오피스에서 경험하는 리프레시 서비스"
              descColor="#4267EB"
              button={
                <Button
                  variant="contained"
                  sx={{
                    width: { md: '120px', xs: '80px' },
                    height: { md: '40px', xs: '30px' },
                    fontSize: { md: 14, xs: 12 }
                  }}
                  onClick={() => navigate('/offresh')}
                >
                  더 알아보기
                </Button>
              }
            />
          </Stack>
          <Stack data-aos="fade-up">
            <ServiceCard
              bgColor="#D9D9D9"
              title={
                <span style={{ fontWeight: '700', fontFamily: 'Poppins' }}>
                  SPOT
                </span>
              }
              titleColor="#bdbdbd"
              desc="당신이 원하는 모든 것 이 ‘One Spot’에"
              descColor="#bdbdbd"
              button={
                <Typography
                  color="#4267EB"
                  fontWeight={{ md: 600, xs: 700 }}
                  fontSize={{ md: 16, xs: 12 }}
                  mb={{ md: '-10px', xs: '10px' }}
                >
                  서비스 준비중 입니다.
                </Typography>
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Service;
