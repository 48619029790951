import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import './index.css';
import Footer from './Footer';

const Layout = () => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
