/* eslint-disable react/no-unescaped-entities */
import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import PhoneIcon from '@mui/icons-material/Call';
// import img src={msgIcon} alt="img" from '@mui/icons-material/Mail';
import phoneIcon from 'images/icon/icon-phone.svg';
import msgIcon from 'images/icon/icon-message.svg';

const Footer = () => {
  return (
    <Stack
      sx={{
        backgroundColor: '#111111',
        width: '100%',
        color: '#D3D6E4'
      }}
    >
      <Stack
        sx={{
          maxWidth: { md: '1920px', xs: '100%' },
          margin: 'auto',
          width: '100%',
          p: {
            lg: '70px 120px',
            md: '70px 60px'
          }
        }}
      >
        <Hidden mdDown>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack flexDirection="row" alignItems="center" gap={3} mb={2}>
              <Typography color="white">(주)워커스하이</Typography>
              <Typography sx={{ textDecoration: 'underline' }}>
                이용약관
              </Typography>
              <Typography sx={{ textDecoration: 'underline' }}>
                개인정보 처리 방침
              </Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                borderRadius="20px"
                border="1px solid #4E4E4E"
                p="7px 20px"
              >
                <img src={phoneIcon} alt="img" />
                <Typography>02-2038-3679</Typography>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                borderRadius="20px"
                border="1px solid #4E4E4E"
                p="7px 20px"
              >
                <img src={msgIcon} alt="img" />
                <Typography>service@workershigh.com</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography fontSize={14} variant="h7" fontWeight={300}>
                서울특별시 강남구 선릉로 615, 2층
              </Typography>
              <FiberManualRecordIcon sx={{ width: '5px' }} />
              <Typography fontSize={14} fontWeight={300}>
                사업자등록번호 181-81-02285
              </Typography>
              <FiberManualRecordIcon sx={{ width: '5px' }} />
              <Typography fontSize={14} fontWeight={300}>
                통신판매업신고증 제2021-서울강남-02166호
              </Typography>
            </Stack>
            <Typography fontSize={13} fontWeight={300} mt={1.5}>
              COPYRIGHT © Worker's high Co.,Ltd. ALL RIGHTS RESERVED.
            </Typography>
          </Stack>
        </Hidden>
        <Hidden mdUp>
          <Stack>
            <Stack
              height="44px"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap={3}
              borderBottom="1px solid #414141"
            >
              <Typography fontSize={12} sx={{ textDecoration: 'underline' }}>
                이용약관
              </Typography>
              <Typography fontSize={12} sx={{ textDecoration: 'underline' }}>
                개인정보 처리 방침
              </Typography>
            </Stack>
            <Stack flexDirection="column" alignItems="center" p="32px">
              <Typography color="white">(주)워커스하이</Typography>
              <Stack flexDirection="column" alignItems="center" mt="15px">
                <Typography fontSize={12} variant="h7" fontWeight={300}>
                  서울특별시 강남구 선릉로 615,2층
                </Typography>
                <Typography fontSize={12} fontWeight={300}>
                  사업자등록번호 181-81-02285
                </Typography>
                <Typography fontSize={12} fontWeight={300}>
                  통신판매업신고증 제2021-서울강남-02166호
                </Typography>
              </Stack>
              <Stack
                flexDirection="column"
                alignItems="center"
                mt="15px"
                mb="30px"
              >
                <Typography fontSize={11} fontWeight={300}>
                  COPYRIGHT © Worker's high Co.,Ltd.
                </Typography>
                <Typography fontSize={11} fontWeight={300}>
                  ALL RIGHTS RESERVED.
                </Typography>
              </Stack>
              <Stack flexDirection="column" alignItems="center" gap={1}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  borderRadius="20px"
                  border="1px solid #4E4E4E"
                  p="5px 15px"
                >
                  <img src={phoneIcon} alt="img" />
                  <Typography fontSize={12}>02-2038-3679</Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  borderRadius="20px"
                  border="1px solid #4E4E4E"
                  p="5px 15px"
                >
                  <img src={msgIcon} alt="img" />
                  <Typography fontSize={12}>service@workershigh.com</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Hidden>
      </Stack>
    </Stack>
  );
};

export default Footer;
