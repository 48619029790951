import RestService from '@services/RestService';
import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';

const fileService = {
  async downloadExternalFile(data = {}) {
    try {
      const res = await new RestService()
        .setPath('/file/external-download')
        .setResponseType('blob')
        .post(data);
      const fileName = getFilenameFromHeader(res.headers);
      if (res?.data) {
        downloadFileFromBinary(res.data, fileName);
      }
      return {
        data: res?.data,
        error: null
      };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default fileService;
