import { Stack, Typography } from '@mui/material';
import React from 'react';

export const BGABC = () => {
  return (
    <Stack>
      <Stack
        className="bgABC"
        sx={{
          height: { md: '600px', xs: '420px' }
        }}
      >
        <Stack
          sx={{
            maxWidth: '1260px',
            width: '88%',
            margin: { md: 'auto', xs: '50px auto' }
          }}
        >
          <Typography
            data-aos="fade-up"
            data-aos-delay="300"
            fontSize={{ md: 48, xs: 26 }}
            fontWeight={700}
            textAlign={{ md: 'left', xs: 'center' }}
          >
            오프레시의 <br />
            특별한 관리 서비스
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
