import React, { forwardRef } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  TextField
} from '@mui/material';
import { ReactComponent as ArrowDownBoldIcon } from '@svg/arrow-down-bold.svg';
import { useTranslation } from 'react-i18next';

const MyAutocomplete = forwardRef(
  ({ label, textFieldProps, required, sx, errMg, ...rest }, ref) => {
    const { t } = useTranslation();

    if (!label)
      return (
        <Autocomplete
          size="small"
          sx={{
            width: 250,
            '.MuiInputBase-root': { bgcolor: 'common.white' },
            ...sx
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errMg}
              inputRef={ref}
              {...textFieldProps}
              helperText={errMg && `* ${errMg}`}
            />
          )}
          slotProps={{
            popupIndicator: { sx: { width: '24px', height: '24px' } }
          }}
          popupIcon={<ArrowDownBoldIcon />}
          ref={ref}
          {...rest}
        />
      );
    return (
      <FormControl sx={{ flexDirection: 'row' }}>
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }
          }}
          control={
            <Autocomplete
              disablePortal
              size="small"
              sx={{
                width: 300,
                ml: 1,
                '.MuiInputBase-root': { bgcolor: 'common.white' },
                ...sx
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errMg}
                  {...textFieldProps}
                  helperText={errMg && `* ${errMg}`}
                />
              )}
              slotProps={{
                popupIndicator: { sx: { width: '24px', height: '24px' } }
              }}
              popupIcon={<ArrowDownBoldIcon />}
              noOptionsText={t('common.noOptions')}
              ref={ref}
              {...rest}
            />
          }
        />
      </FormControl>
    );
  }
);

export default MyAutocomplete;
