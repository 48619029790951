import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';

const MyLoadingButton = (props) => {
  const { onClick, children, disabled, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Button
      startIcon={loading && <CircularProgress size={20} />}
      disabled={disabled || loading}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default React.memo(MyLoadingButton);
