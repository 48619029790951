import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';

const Banner = () => {
  return (
    <Stack
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        pb: {
          md: '80px',
          xs: '20px'
        },
        width: '88%'
      }}
    >
      <Hidden mdDown>
        <Stack
          sx={{
            flexDirection: {
              md: 'row',
              sm: 'column'
            },
            flexWrap: 'wrap'
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            sx={{
              width: { md: '40%', sm: '100%' },
              mb: { md: '40px' }
            }}
          >
            <Typography
              fontWeight={500}
              fontFamily="Poppins"
              sx={{ mb: '-15px', fontSize: 70 }}
            >
              About us
            </Typography>
          </Stack>
          <Stack
            flexDirection="column"
            gap={2}
            sx={{
              width: { md: '60%', sm: '100%' },
              mb: { md: '40px' }
            }}
          >
            <Typography color="#9C9C9C" fontWeight={700} fontSize={32}>
              세상에서 가장 가까운 나만의 스토어
            </Typography>
            <Typography fontWeight={400} color="#555555" fontSize={24}>
              가장 가까운 곳에서 만나는 NEXT RETAIL <br /> 필요한 상품을
              구매하기 위해 쓰이는 불필요한 과정들을 제거해 <br />
              혁신적인 구매 경험을 제공합니다.
            </Typography>
            <Typography fontSize={24} fontWeight={400} color="#555555">
              공간의 제약을 뛰어넘어 바쁜 현대인의 시간을 아끼고 개인화된 상품을
              제공해 선택의 고민을 줄이는 가장 진화된 리테일 (워커스하이)
              입니다.
            </Typography>
          </Stack>
          <div className="bg-1" />
        </Stack>
      </Hidden>
      <Hidden mdUp>
        <Stack flexDirection="column" alignItems="center" gap={3}>
          <Typography fontFamily="Poppins" fontSize={32} fontWeight={500}>
            About us
          </Typography>
          <div className="bg-1" />
        </Stack>
        <Stack
          flexDirection="column"
          gap={1}
          sx={{ mt: '20px', width: '100%', p: 1 }}
        >
          <Typography fontSize={20} color="#9C9C9C" fontWeight={700}>
            세상에서 가장 가까운 나만의 스토어
          </Typography>
          <Typography fontWeight={400} color="#555555" sx={{ fontSize: 14 }}>
            가장 가까운 곳에서 만나는 NEXT RETAIL 필요한 상품을 구매하기 위해
            쓰이는 불필요한 과정들을 제거해 혁신적인 구매 경험을 제공합니다.
          </Typography>
          <Typography fontWeight={400} color="#555555" sx={{ fontSize: 14 }}>
            공간의 제약을 뛰어넘어 바쁜 현대인의 시간을 아끼고 개인화된 상품을
            제공해 선택의 고민을 줄이는 가장 진화된 리테일 (워커스하이) 입니다.
          </Typography>
        </Stack>
      </Hidden>
    </Stack>
  );
};

export default Banner;
