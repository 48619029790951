import { Stack, Typography } from '@mui/material';
import React from 'react';
import './FeedBackCard.css';

const FeedBackCard = ({ content, name }) => {
  return (
    <Stack
      flexDirection="column"
      sx={{
        gap: { md: 2, xs: 1 },
        p: '0 20px',
        height: { md: '330px', xs: '212px' },
        bgcolor: 'white',
        borderRadius: '30px'
      }}
      alignItems="center"
      justifyContent="center"
    >
      <span className="quote1" />
      <Typography
        sx={{
          fontSize: { md: 20, xs: 14 },
          fontWeight: { md: 500, xs: 400 },
          letterSpacing: '0.5px'
        }}
        textAlign="center"
      >
        {content}
      </Typography>
      <Typography
        sx={{ fontSize: { md: 20, xs: 14 }, letterSpacing: '0.5px' }}
        color="primary"
      >
        {name}
      </Typography>
      <span className="quote2" />
    </Stack>
  );
};

export default FeedBackCard;
