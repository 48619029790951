import React, { useEffect, useState } from 'react';
import useMergeState from '@hooks/useMergeState';

import {
  Check,
  CheckCircle,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Box, Button, Checkbox, Stack, Typography } from '@mui/material';

import MyDialog from '@components/dialogs/MyDialog';
import MyTextField from '@components/inputs/MyTextField';
import LoadingButton from '@components/buttons/LoadingButton';
import { Formatter } from '@utils/formatter';
import { isEmail, is11Number } from '@utils/boolean';
import codeService from '@services/code';
import consultationInquiryService from '@services/consultationInquiry';

import letterImg from 'images/inquiry/inquiry_img4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
// import { onlyNumber } from '@utils/helpers';

const useStyles = makeStyles((theme) => ({
  letterImg: {
    [theme.breakpoints.down('md')]: {
      width: '80px',
      height: '80px'
    }
  }
}));

const services = ['오피스', '호텔', '스포츠시설', '기타'];

const maxLengthOf = {
  companyName: 50,
  picName: 50,
  phone: 20,
  email: 40
};

const ConsultationInquiryForm = (props) => {
  const { turnBack, closeModal } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inquiryData, setInquiryData] = useMergeState({
    service: '오피스',
    companyName: '',
    picName: '',
    phone: '',
    email: ''
  });
  const [errMsg, setErrMsg] = useMergeState({
    phone: '',
    otp: '',
    email: ''
  });
  const [otpCode, setOtpCode] = useState('');
  const [accordion, setAccordion] = useState('none');
  const [accept, setAccept] = useState(false);
  const [verifyingBox, setVerifyingBox] = useState('none');
  const [isVerifying, setVerifying] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [seconds, setSeconds] = useState(-1);
  const [nofityDialog, setNofityDialog] = useState(undefined);
  const openInquiry = useSelector((state) => state.app.openInquiry);

  const handleTurnBack = () => {
    if (openInquiry) {
      closeModal();
    }
    setTimeout(() => {
      turnBack();
    }, 111);
  };

  const handleChange = (name, value) => {
    if (name === 'email') {
      setErrMsg({
        email: isEmail(value)
          ? ''
          : '올바른 이메일 주소가 아닙니다. 확인 후 다시 입력해주세요.'
      });
    } else if (name === 'phone') {
      setErrMsg({
        phone: is11Number(value)
          ? ''
          : '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
      });
    }
    if (value?.length < maxLengthOf[name]) {
      setInquiryData({ [name]: value });
    }
  };

  const handleCheckAccept = (e) => {
    setAccept(e.target.checked);
    setAccordion(e.target.checked ? true : 'none');
  };

  const handleSendOTP = async () => {
    if (!inquiryData.phone || errMsg.phone) {
      setErrMsg({ phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.' });
      dispatch(
        setError({ title: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.' })
      );
      return;
    }
    const data = new FormData();
    data.append('phone', inquiryData.phone);
    const res = await codeService.sendOTP(data);
    if (!res?.error) {
      setErrMsg({ otp: '' });
      setOtpCode('');
      setVerifyingBox('init');
      setVerifying(true);
      setVerified(false);
      setSeconds(180);
      setNofityDialog({
        title: '인증번호를 발송했습니다.',
        content: '수신된 인증번호를 3분안에 입력해주세요'
      });
    } else {
      setNofityDialog({
        title: res.error?.message
      });
    }
  };

  const handleVerifyOTP = async () => {
    const data = new FormData();
    data.append('phone', inquiryData.phone);
    data.append('otp', otpCode);
    const res = await codeService.verifyOTP(data);
    if (!res?.error) {
      setVerifying(false);
      setVerified(true);
      setNofityDialog({
        title: '인증이 완료되었습니다.'
      });
    } else {
      setNofityDialog({
        title: res.error?.message
      });
    }
  };

  const handleValidateForm = () => {
    let isValid = true;
    Object.values(inquiryData).forEach((field) => {
      if (!field) {
        isValid = false;
      }
    });
    Object.values(errMsg).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    if (isVerified && isValid) {
      setNofityDialog({
        title: '입력하신 정보로 상담 신청을 완료하시겠습니까?',
        hasCancelButton: true,
        onOK: () => handleCreateInquiry()
      });
    } else {
      setNofityDialog({
        title: '유효하지 않은 데이터',
        content: '입력 내용을 확인하십시오.'
      });
    }
  };

  const handleCreateInquiry = async () => {
    const data = new FormData();
    data.append('service', inquiryData.service);
    data.append('companyName', inquiryData.companyName);
    data.append('picName', inquiryData.picName);
    data.append('phone', inquiryData.phone);
    data.append('email', inquiryData.email);

    const res = await consultationInquiryService.createInquiry(data);
    if (!res.error) {
      setNofityDialog({
        title: (
          <Stack sx={{ alignItems: 'center' }}>
            <img
              className={classes.letterImg}
              src={letterImg}
              alt="서비스 소개서"
            />
            <div>
              입력하신 이메일로
              <span style={{ color: '#4267EB' }}>서비스 소개서를</span>
              보내드렸으며, 상담사가 순차적으로 연락드릴 예정입니다.
            </div>
          </Stack>
        ),
        content: (
          <span style={{ color: '#4267EB' }}>(평균 1일 ~ 2일 소요 예정)</span>
        ),
        onOK: () => {
          setNofityDialog(undefined);
          closeModal();
          turnBack();
        },
        onClose: () => {
          setNofityDialog(undefined);
          closeModal();
          turnBack();
        }
      });
    } else {
      setNofityDialog({
        title: res.error?.message
      });
    }
  };

  useEffect(() => {
    if (isVerifying && seconds === 0) {
      setErrMsg({
        otp: '인증시간이 초과되었습니다, 인증번호 발송을 다시 진행해주세요.'
      });
      setVerifying(false);
    }
  }, [seconds]);

  return (
    <Box>
      {/* service */}
      <Stack sx={{ mb: { md: '60px', xs: '40px' } }}>
        <Typography sx={{ fontSize: { md: 20, xs: 14 }, fontWeight: 500 }}>
          <span style={{ color: 'red', fontSize: 20 }}>*</span>
          서비스가 필요한 공간을 선택해 주세요!
        </Typography>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            mt: { md: '30px', xs: '20px' }
          }}
        >
          {services.map((item) => (
            <Stack
              onClick={() => setInquiryData({ service: item })}
              sx={{
                width: { md: '24%', xs: '49%' },
                height: { md: '70px', xs: '50px' },
                mb: { md: 2, xs: 1 },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: { md: '20px !important', xs: '10px !important' },
                fontSize: { md: 20, xs: 13 },
                bgcolor: inquiryData.service === item ? '#4267EB' : '#F5F5F5',
                color: inquiryData.service === item ? '#FFF' : '#000',
                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: '#4267EB',
                  color: '#FFF'
                }
              }}
            >
              {inquiryData.service === item && <Check sx={{ mr: 1 }} />}
              {item}
            </Stack>
          ))}
        </Stack>
      </Stack>
      {/* companyName */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'row',
          mb: { md: '60px', xs: '50px' }
        }}
      >
        <MyTextField
          required
          fullWidth
          label="회사명"
          placeholder="회사 이름을 알려주세요"
          value={inquiryData.companyName}
          onChange={(e) => handleChange('companyName', e.target.value)}
          onClear={() => handleChange('companyName', '')}
        />
        <Stack visibility="hidden" sx={{ width: { md: '30%', xs: 0 } }}>
          invisible
        </Stack>
      </Stack>
      {/* picName */}
      <Stack
        sx={{
          flexDirection: 'row',
          mb: { md: '60px', xs: '50px' }
        }}
      >
        <MyTextField
          required
          fullWidth
          label="담당자"
          placeholder="담당자 이름을 입력해주세요"
          value={inquiryData.picName}
          onChange={(e) => handleChange('picName', e.target.value)}
          onClear={() => handleChange('picName', '')}
        />
        <Stack visibility="hidden" sx={{ width: { md: '30%', xs: 0 } }}>
          invisible
        </Stack>
      </Stack>
      {/* phone */}
      <Stack
        sx={{
          flexDirection: 'column',
          mb: { md: '60px', xs: '50px' }
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
        >
          <MyTextField
            required
            fullWidth
            label="연락처"
            placeholder="저희가 연락드릴 휴대폰 번호를 알려주세요"
            value={inquiryData.phone}
            // onChange={(e) => handleChange('phone', e.target.value)}
            onChange={(e) => {
              handleChange('phone', e.target.value.replace(/\D/g, ''));
            }}
            onClear={() => handleChange('phone', '')}
            // onKeyDown={onlyNumber}
            errMg={errMsg.phone}
          />

          <Stack width="30%" sx={{ pl: { md: 3, xs: 1 } }}>
            <LoadingButton
              variant="outlined"
              onClick={handleSendOTP}
              disabled={isVerifying || errMsg.phone || !inquiryData.phone}
              sx={{ height: { md: 60, xs: 44 }, fontSize: { md: 20, xs: 13 } }}
            >
              인증번호 발송
            </LoadingButton>
          </Stack>
        </Stack>
        {errMsg.phone && (
          <Typography
            sx={{
              fontSize: { md: 14, xs: 12 },
              color: 'red',
              ml: { md: '110px', xs: '0' }
            }}
          >
            {errMsg.phone}
          </Typography>
        )}
      </Stack>
      {/* Check OTP */}
      <Stack
        display={verifyingBox}
        sx={{
          flexDirection: 'column',
          mb: { md: '60px', xs: '50px' }
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-end'
          }}
        >
          <MyTextField
            fullWidth
            label="인증번호"
            placeholder="인증번호"
            value={otpCode}
            onChange={(e) => {
              if (e.target.value?.length <= 4) {
                setOtpCode(e.target.value);
              }
            }}
            endComponent={
              isVerified ? (
                <CheckCircle
                  sx={{
                    fontSize: { md: '38px', xs: '24px' },
                    color: 'green !important'
                  }}
                />
              ) : (
                <CountTime
                  isVerifying={isVerifying}
                  seconds={seconds}
                  setSeconds={setSeconds}
                />
              )
            }
            errMg={errMsg.otp}
            disabled={seconds <= 0 || isVerified}
          />
          <Stack
            sx={{
              width: { md: '30%', xs: isVerified ? 0 : '30%' },
              pl: { md: '24px', xs: '12px' }
            }}
          >
            <LoadingButton
              variant="outlined"
              onClick={handleVerifyOTP}
              disabled={seconds <= 0}
              sx={{
                display: isVerified ? 'none' : '',
                width: { md: 100, xs: 70 },
                height: { md: 60, xs: 44 },
                fontSize: { md: 20, xs: 13 }
              }}
            >
              확인
            </LoadingButton>
          </Stack>
        </Stack>
        {errMsg.otp && (
          <Typography
            sx={{
              fontSize: { md: 14, xs: 12 },
              color: 'red',
              ml: { md: '110px', xs: '0' }
            }}
          >
            {errMsg.otp}
          </Typography>
        )}
      </Stack>
      {/* Email */}
      <Stack
        sx={{
          flexDirection: 'column',
          mb: { md: '60px', xs: '50px' }
        }}
      >
        <Stack sx={{ flexDirection: 'row' }}>
          <MyTextField
            required
            fullWidth
            label="이메일"
            placeholder="서비스 소개서를 받으실 이메일 주소를 알려주세요"
            value={inquiryData.email}
            onChange={(e) => handleChange('email', e.target.value)}
            onClear={() => handleChange('email', '')}
          />
          <Stack visibility="hidden" sx={{ width: { md: '30%', xs: 0 } }}>
            invisible
          </Stack>
        </Stack>
        {errMsg.email && (
          <Typography
            sx={{
              fontSize: { md: 14, xs: 12 },
              color: 'red',
              ml: { md: '110px', xs: '0' }
            }}
          >
            {errMsg.email}
          </Typography>
        )}
      </Stack>
      {/* Terms of service */}
      <Stack
        sx={{
          bgcolor: '#F5F5F5',
          borderRadius: { md: '20px', xs: '10px' },
          p: { md: '30px 40px', xs: '20px 16px' }
        }}
      >
        <Stack
          sx={{
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: { md: 20, xs: 13 }
          }}
        >
          <Stack direction="row">
            <Checkbox
              checked={accept}
              onChange={handleCheckAccept}
              sx={{
                width: { md: '32px', xs: '20px' },
                height: { md: '32px', xs: '20px' },
                mr: '5px'
              }}
            />
            <span style={{ color: 'red' }}>*</span>
            이용약관 및 개인정보 처리방침에 동의합니다.
          </Stack>
          <Stack
            onClick={() => setAccordion(accordion === 'none' ? true : 'none')}
            sx={{
              flexDirection: 'row',
              color: '#4267EB',
              fontSize: { md: 16, xs: 12 },
              fontWeight: 500,
              textDecoration: 'underline',
              mt: { md: 0, xs: '16px' },
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            약관보기
            {accordion === 'none' ? (
              <ExpandMore
                sx={{
                  width: { md: '24px', xs: '18px' },
                  height: { md: '24px', xs: '18px' }
                }}
              />
            ) : (
              <ExpandLess
                sx={{
                  width: { md: '24px', xs: '18px' },
                  height: { md: '24px', xs: '18px' }
                }}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          display={accordion}
          sx={{ fontSize: { md: 16, xs: 12 }, mt: '30px' }}
        >
          개인정보 수집 및 이용에 대한 동의
          <br />
          <br />① 개인정보 수집 항목: 이름, 연락처, 이메일
          <br />② 수집목적 : 본인인증, 구매내역 알림, 포인트 적립, 고객식별,
          문의 응대, 서비스 품질 향상
          <br />③ 보유 및 이용기간 : 수집 목적이 달성되면 지체없이 모든
          개인정보를 파기합니다. 단, 관계법령에서 일정 기간 정보의 보관을 규정한
          경우에 한해 분리 보관 후 파기합니다.
          <br />④ 알람 동의 : 상품 및 경품/쿠폰 제공 및 배송을 위한 알림에
          동의합니다.
          <br />
          <br />· 위 동의는 거부할 수 있으며, 거부시 휴대폰 번호로 안내받으실 수
          없습니다.
        </Stack>
      </Stack>
      {/* Action Buttons */}
      <Stack
        direction="row"
        sx={{
          mt: 10,
          display: 'flex',
          justifyContent: 'center',
          gap: { md: '20px', xs: '10px' }
        }}
      >
        <Button
          variant="outlined"
          onClick={handleTurnBack}
          sx={{
            height: { md: 60, xs: 44 },
            width: { md: 190, xs: 120 },
            fontSize: { md: 20, xs: 13 }
          }}
        >
          이전단계
        </Button>
        <Button
          variant="contained"
          disabled={!accept}
          onClick={handleValidateForm}
          sx={{
            height: { md: 60, xs: 44 },
            width: { md: 190, xs: 120 },
            fontSize: { md: 20, xs: 13 }
          }}
        >
          신청하기
        </Button>
      </Stack>
      {/* Notify Dialog */}
      <MyDialog
        open={nofityDialog !== undefined}
        setOpen={() => setNofityDialog(undefined)}
        title={nofityDialog?.title}
        content={nofityDialog?.content}
        onOk={nofityDialog?.onOK}
        onClose={nofityDialog?.onClose}
        hasCancelButton={nofityDialog?.hasCancelButton}
        {...nofityDialog}
      />
    </Box>
  );
};

const CountTime = (props) => {
  const { isVerifying, seconds, setSeconds } = props;
  useEffect(() => {
    setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
  }, [isVerifying && seconds > 0 && seconds]);
  return (
    <Stack sx={{ fontSize: { md: 20, xs: 14 }, color: '#4267EB' }}>
      {Formatter.fSecondsToMinutes(seconds)}
    </Stack>
  );
};

export default ConsultationInquiryForm;
