import { Stack, Typography } from '@mui/material';
import React from 'react';

import img1 from 'images/cargo-img1.svg';
import img2 from 'images/cargo-img2.svg';
import img3 from 'images/cargo-img3.svg';

import img21 from 'images/sub2/sub2_img5.png';
import img22 from 'images/sub2/sub2_1_img3.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '80px'
    }
  },
  img2: {
    height: '400px',
    borderRadius: '30px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '270px'
    }
  }
}));

export const Map = () => {
  const classes = useStyles();
  return (
    <>
      <Stack
        sx={{
          maxWidth: '1260px',
          margin: 'auto',
          width: '88%'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            pb: { md: '100px 0 0 0', xs: '40px 0 0 0' }
          }}
        >
          <Stack m={{ md: '92px 0', xs: '30px 0' }}>
            <Typography
              textAlign="left"
              fontSize={{ md: 36, xs: 20 }}
              fontWeight="bold"
              letterSpacing="0.5px"
            >
              서울 핵심 위치의 물류센터
            </Typography>
            <Typography
              fontSize={{ md: 24, xs: 14 }}
              color="#555"
              letterSpacing="0.5px"
            >
              오피스 밀집 구역인 강남역 인접 워커스하이 물류센터
            </Typography>
          </Stack>
          <Stack flexDirection={{ md: 'row', xs: 'column' }} width="100%">
            <Stack
              width={{ md: '50%', xs: '100%' }}
              pr={{ md: '30px', xs: '0' }}
              mb={{ md: '0', xs: '25px' }}
            >
              <div className="bg-map" />
            </Stack>
            <Stack
              width={{ md: '50%', xs: '100%' }}
              maxHeight="600px"
              className="map-item"
            >
              <Stack
                flexDirection="column"
                height="100%"
                gap={{ md: '0', xs: 2 }}
              >
                <Stack
                  m={{ md: '0 0 15px 20px' }}
                  height="33%"
                  flexDirection="row"
                  alignItems="center"
                  bgcolor="#F6FAFF"
                  borderRadius="20px"
                  p={{ md: '15px 40px', xs: '10px 16px' }}
                >
                  <Stack mr={{ md: '40px', xs: '16px' }}>
                    <img className={classes.img} src={img1} alt="img" />
                  </Stack>
                  <Typography
                    fontSize={{ md: 28, xs: 16 }}
                    fontWeight={500}
                    letterSpacing="0.5px"
                  >
                    물품 보관 및 유통
                    <br />
                    프로세스 최적화
                  </Typography>
                </Stack>
                <Stack
                  m={{ md: '15px 0 15px 20px' }}
                  height="33%"
                  flexDirection="row"
                  alignItems="center"
                  bgcolor="#F6FAFF"
                  borderRadius="20px"
                  p={{ md: '15px 40px', xs: '10px 16px' }}
                >
                  <Stack mr={{ md: '40px', xs: '16px' }}>
                    <img className={classes.img} src={img2} alt="img" />
                  </Stack>
                  <Typography
                    fontSize={{ md: 28, xs: 16 }}
                    fontWeight={500}
                    letterSpacing="0.5px"
                  >
                    배송 및 운영/관리에
                    <br />
                    유리한 위치
                  </Typography>
                </Stack>
                <Stack
                  m={{ md: '15px 0 0 20px' }}
                  height="34%"
                  flexDirection="row"
                  alignItems="center"
                  bgcolor="#F6FAFF"
                  borderRadius="20px"
                  p={{ md: '15px 40px', xs: '10px 16px' }}
                >
                  <Stack mr={{ md: '40px', xs: '16px' }}>
                    <img className={classes.img} src={img3} alt="img" />
                  </Stack>
                  <Typography
                    fontSize={{ md: 28, xs: 16 }}
                    fontWeight={500}
                    letterSpacing="0.5px"
                  >
                    강남 인근 고객을 위한
                    <br />
                    유동적인 배송/관리 스케쥴
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          maxWidth: '1260px',
          margin: 'auto',
          width: '88%'
        }}
      >
        <Typography
          textAlign="left"
          fontSize={{ md: 36, xs: 20 }}
          fontWeight={700}
          m={{ md: '60px 0', xs: '40px 0 30px 0' }}
          letterSpacing="0.5px"
        >
          체계적인 관리 시스템
        </Typography>
        <Stack
          flexDirection={{ md: 'row', xs: 'column' }}
          width="100%"
          gap={{ md: '0', xs: '30px' }}
        >
          <Stack width={{ md: '50%', xs: '100%' }} mr={{ md: '25px' }}>
            <MapCard
              src={img21}
              alt="img"
              title="전문적인 전담 관리 매니저"
              desc="워커스하이의 서비스별로 전담 관리 매니저를 배치하여, 보다 더 전문적인 발주 프로세스, 상품 진열, 상품 퀄리티 및 재고 관리가 가능합니다."
            />
          </Stack>
          <Stack width={{ md: '50%', xs: '100%' }} ml={{ md: '25px' }}>
            <MapCard
              src={img22}
              alt="img"
              title="고객 맞춤형 배송 스케쥴링"
              desc="워커스하이의 스낵 배송 서비스는, 고객의 희망 주기와 스토어 상황에 맞춰 유동적인 배송 스케쥴링이 가능합니다."
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const MapCard = ({ src, title, desc }) => {
  const classes = useStyles();
  return (
    <Stack flexDirection="column" width="100%" sx={{ wordBreak: 'keep-all' }}>
      <Stack sx={{ height: { md: '400px', xs: '270px' } }}>
        <img className={classes.img2} src={src} alt="img" />
      </Stack>
      <Stack>
        <Typography
          color="black"
          sx={{
            fontSize: { md: 28, xs: 16 },
            fontWeight: 'bold'
          }}
          m={{ xs: '20px 0 5px 0' }}
          textAlign={{ md: 'none', xs: 'left' }}
          letterSpacing="0.4px"
        >
          {title}
        </Typography>
        <Typography
          color="#555"
          sx={{
            fontSize: { md: 20, xs: 14 },
            wordBreak: 'keep-all',
            lineHeight: { md: '25px', xs: '19px' }
          }}
          fontWeight={400}
          textAlign={{ md: 'none', xs: 'left' }}
          letterSpacing="0.4px"
        >
          {desc}
        </Typography>
      </Stack>
    </Stack>
  );
};
