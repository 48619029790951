import { Hidden, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './index.css';
import SwiperButton from '@components/buttons/SwiperButton';

// import required modules
import { Pagination } from 'swiper';
import FeedBackCard from '@components/cards/FeedBackCard';

const FeedBack = () => {
  const [swiper, setSwiper] = useState(null);
  const [index, setIndex] = useState(0);
  return (
    <Stack
      sx={{
        backgroundColor: '#F6FAFE',
        width: '100%'
      }}
    >
      <Stack
        maxWidth={{ md: '1920px', xs: '100%' }}
        margin="auto"
        sx={{
          gap: { md: 5, xs: 2 },
          p: {
            md: '120px 0',
            xs: '50px 0 30px 0'
          }
        }}
        width="100%"
      >
        <Stack>
          <Typography
            sx={{
              fontSize: { md: 32, xs: 20 },
              fontWeight: 'bold',
              width: { md: '99%', xs: '88%' },
              margin: 'auto',
              wordBreak: 'keep-all',
              letterSpacing: '0.5px'
            }}
          >
            많은 분들이 워커스하이의 서비스에 만족하셨습니다.
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            p: {
              md: '0 100px',
              sm: '0 50px',
              xs: '0 20px'
            }
          }}
        >
          <Hidden mdDown>
            <Stack mr="100px">
              <SwiperButton
                type="prev"
                disabled={index === 0 || index === 1}
                swiper={swiper}
              />
            </Stack>
          </Hidden>
          <Swiper
            className="swiper-fb"
            slidesPerView={1}
            spaceBetween={20}
            onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
            onActiveIndexChange={(current) => setIndex(current?.activeIndex)}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            breakpoints={{
              1024: {
                initialSlide: 1,
                slidesPerView: 3,
                spaceBetween: 45,
                pagination: false,
                centeredSlides: true
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30
              }
            }}
          >
            <SwiperSlide className="swiper-slide-fb">
              <FeedBackCard
                content={
                  <span>
                    저희 사무실 환경에 딱 맞는
                    <br />
                    예쁜 간식 매대가 들어와서
                    <br />
                    사무실 분위기까지 바뀌었어요!
                  </span>
                }
                name="A 고객사 담당자님"
              />
            </SwiperSlide>
            <SwiperSlide>
              <FeedBackCard
                content={
                  <span>
                    정성스럽게 진열해주셔서
                    <br />
                    정돈된 느낌이 너무 좋아요!
                  </span>
                }
                name="B 고객사 담당자님"
              />
            </SwiperSlide>
            <SwiperSlide>
              <FeedBackCard
                content={
                  <span>
                    필요할 땐 상품을 직접 선택해도 되고,
                    <br />
                    고민스러울 땐 큐레이션을
                    <br />
                    요청드려도 되서 너무 편리해요
                  </span>
                }
                name="C 고객사 담당자님"
              />
            </SwiperSlide>
            <SwiperSlide>
              <FeedBackCard
                content={
                  <span>
                    사무실에서 출출할 때 멀리 나가지
                    <br />
                    않아도 되고 쉽고 간편하게
                    <br />
                    이용할 수 있어서 좋아요.
                  </span>
                }
                name="D 고객사 담당자님"
              />
            </SwiperSlide>
          </Swiper>
          <Hidden mdDown>
            <Stack ml="100px">
              <SwiperButton
                type="next"
                disabled={index === 2 || index === 3}
                swiper={swiper}
              />
            </Stack>
          </Hidden>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FeedBack;
