export const downloadFileFromBinary = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const getFilenameFromHeader = (header, key = 'Content-Disposition') => {
  try {
    const disposition = header.get(key);
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        return decodeURI(matches[1].replace(/['"]/g, '')).replaceAll('+', ' ');
      }
    }
  } catch (e) {
    if (process.env.NODE_ENV !== 'production')
      console.log('getFilenameFromHeader', e);
  }
  return '';
};
