import ServiceInquiryCard from '@components/cards/ServiceInquiryCard';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import card1 from 'images/inquiry/inquiry_img1.svg';
// import card2 from 'images/inquiry/inquiry_img2.svg';
import card3 from 'images/inquiry/inquiry_img3.svg';

const ServiceInquiry = ({ selected, setSelected, onSelected }) => {
  return (
    <Stack flexDirection="column" justifyContent="center" alignItems="center">
      <Typography
        sx={{ fontSize: { md: 28, xs: 20 }, mb: { md: '40px', xs: '24px' } }}
      >
        원하시는 서비스를 선택해주세요!
      </Typography>
      <Stack
        sx={{
          flexDirection: { md: 'row', xs: 'column' },
          width: '100%',
          justifyContent: 'space-between'
        }}
        gap={2}
      >
        <Stack sx={{ width: { md: '50%', xs: '100%' } }}>
          <ServiceInquiryCard
            src={card1}
            title="무료 상담신청"
            desc="더 자세한 정보가 필요하신가요?"
            detail="서비스 소개서와 함께 전담 매니저가 연락을 드려요!"
            onClick={() => {
              onSelected(1);
              setSelected(1);
            }}
            selected={selected === 1}
          />
        </Stack>
        {/* <Stack sx={{ width: { md: '33%', xs: '100%' } }}>
          <ServiceInquiryCard
            src={card2}
            title="간편 견적받기"
            desc={
              <span>
                어떤 서비스가 좋을지{' '}
                <Hidden mdDown>
                  {' '}
                  <br />
                </Hidden>{' '}
                고민되시나요?
              </span>
            }
            detail={
              <span>
                희망하시는 서비스를 알려주시면{' '}
                <Hidden mdDown>
                  <br />
                </Hidden>{' '}
                온라인 간편 견적서를 전달해드려요!
              </span>
            }
            onClick={() => {
              onSelected(2);
              setSelected(2);
            }}
            selected={selected === 2}
          />
        </Stack> */}
        <Stack sx={{ width: { md: '50%', xs: '100%' } }}>
          <ServiceInquiryCard
            src={card3}
            title="1:1 문의하기"
            desc="상담원과 1:1 문의를 원하시나요?"
            detail="챗봇을 통해 문의하시거나 카카오톡, 전화 상담을 선택해 주세요!"
            onClick={() => {
              onSelected(3);
              setSelected(3);
            }}
            selected={selected === 3}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ServiceInquiry;
