import { formatMoney } from '@utils/helpers';

const rerender = (item, value) => {
  return (
    <span
      style={{
        textDecoration: item.refund === 'Y' ? 'line-through' : '',
        color: item.refund === 'Y' && 'gray'
      }}
    >
      {value}
    </span>
  );
};

export const columnTotalOrderHistory = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },
  {
    name: 'regDate',
    label: t('common.dateOfTransaction'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) =>
      rerender(
        item,
        new Date(item?.regDate).toLocaleString('en-CA', {
          hour12: false
        })
      )
  },
  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.orderNumber)
  },
  {
    name: 'clientName',
    label: t('common.client'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.clientName)
  },
  {
    name: 'kioskLocation',
    label: t('common.minibarLocation'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.kioskLocation)
  },
  {
    name: 'isMember',
    label: t('common.member'),
    minWidth: 80,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.isMember === 'Y' ? 'Y' : 'N')
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item?.productName)
  },
  {
    name: 'categoryName1',
    label: `1st ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryNameEng1)
  },
  {
    name: 'categoryName2',
    label: `2nd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryNameEng2)
  },
  {
    name: 'categoryName3',
    label: `3rd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryNameEng3)
  },
  {
    name: 'maker',
    label: t('common.manufacturer'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item?.maker)
  },
  {
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.capacity)
  },
  {
    name: 'capacityType',
    label: t('common.package'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.capacityType)
  },
  {
    name: 'price',
    label: t('common.unitPrice'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.price))
  },
  {
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.quantity))
  },
  {
    name: 'totalPrice',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.totalPrice))
  },
  {
    name: 'selectedPrice',
    label: t('common.productTotalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.selectedPrice))
  },
  {
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.discount))
  },
  {
    name: 'totalAmount',
    label: t('common.totalPaymentAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.totalAmount))
  },
  {
    name: `rateUser`,
    label: t('common.userBurden'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.rateUser))
  },
  {
    name: 'rateCompany',
    label: t('common.companyBurden'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.rateCompany))
  }
];

export const columnDetailPayment = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },
  {
    name: 'regDate',
    label: t('common.paymentTime'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) =>
      rerender(
        item,
        new Date(item.regDate).toLocaleString('en-CA', {
          hour12: false
        })
      )
  },
  // {
  //   label: t('common.member'),
  //   minWidth: 80,
  //   align: 'center',
  //   isGroup: true,
  //   groupBy: 'purchaseCode',
  //   dataKey: 'index',
  //   render: (item) => rerender(item, item.memberName || item.phone)
  // },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item.productName)
  },
  {
    name: 'categoryName1',
    label: `1st ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName1)
  },
  {
    name: 'categoryName2',
    label: `2nd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName2)
  },
  {
    name: 'categoryName3',
    label: `3rd ${t('menusidebar.product-management.category')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName3)
  },
  {
    name: 'categoryNameSpecial',
    label: t('common.characterstic'),
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryNameSpecial)
  },
  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item.orderNumber)
  },
  {
    name: 'price',
    label: t('common.price'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.price))
  },
  {
    name: 'cost',
    label: t('common.cost'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.cost))
  },
  {
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.quantity))
  },
  {
    name: 'totalPrice',
    label: t('pages.client-order.totalPrice'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.totalPrice))
  },
  {
    name: 'selectedPrice',
    label: t('common.total'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.selectedPrice))
  },
  {
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.discount))
  },
  {
    name: 'totalAmount',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.totalAmount))
  }
];

export const searchOptions = (t) => [
  { value: 'phone', label: t('common.phoneNo') },
  { value: 'productName', label: t('common.productName') },
  { value: 'totalAmount', label: t('common.totalAmount') }
];

export const radioOptions = (t) => [
  { value: 'Total', label: t('common.total') },
  { value: 'Refund', label: t('common.refund') },
  { value: 'Loss', label: t('common.loss') },
  { value: 'Dispose', label: t('common.dispose') },
  { value: 'Payment', label: t('common.payment') }
];
