import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import iconTalk from 'images/icon/icon-talk.svg';
import iconDocument from 'images/icon/icon-document.svg';
import fileService from '@services/file';
import { useDispatch } from 'react-redux';
import { setErrorLP, setInquiry, setLoading } from '@store/reducers/appReducer';

const useStyles = makeStyles(() => ({
  icons: {
    width: '36px',
    height: '36px'
  }
}));

const QuickMenu = () => {
  const dispath = useDispatch();
  const classes = useStyles();
  const [isMouseOver, setMouseOver] = useState(false);

  const handleOpenInquiryForm = () => {
    dispath(setInquiry(true));
  };

  const handleDownloadFile = async () => {
    dispath(setLoading(true));
    const data = new FormData();
    data.append('type', 'SERVICE_INTRODUCTION');
    const rs = await fileService.downloadExternalFile(data);
    dispath(setLoading(false));
    if (rs?.error) {
      dispath(setErrorLP({ title: rs.error.message }));
    }
  };

  return (
    <Stack
      direction="column"
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      sx={{
        display: { md: 'flex', xs: 'none' },
        justifyContent: 'space-between',
        position: 'fixed',
        top: '500px',
        left: 0,
        zIndex: 1000,
        width: isMouseOver ? '164px' : '60px',
        height: '120px',
        padding: '12px 16px 12px 8px',
        color: 'white',
        background: '#111',
        borderRadius: '0 10px 10px 0',
        fontWeight: 700,
        overflow: 'hidden',
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <Stack
        onClick={handleOpenInquiryForm}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          width: isMouseOver ? '140px' : '36px'
        }}
      >
        <img className={classes.icons} src={iconTalk} alt="helô" />
        <Stack
          sx={{
            display: isMouseOver ? 'flex' : 'none',
            ml: '10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            transition: '0.3'
          }}
        >
          무료 상담 하기
        </Stack>
      </Stack>
      <Stack
        onClick={handleDownloadFile}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          width: isMouseOver ? '140px' : '36px'
        }}
      >
        <img className={classes.icons} src={iconDocument} alt="helô" />
        <Stack
          sx={{
            display: isMouseOver ? 'block' : 'none',
            ml: '10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            transition: '0.3'
          }}
        >
          소개서 받기
        </Stack>
      </Stack>
    </Stack>
  );
};

export default QuickMenu;
