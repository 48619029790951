/* eslint-disable camelcase */
import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';

import logo_wework from 'images/company-logo/logo-wework.svg';
import logo_justco from 'images/company-logo/logo-justco.svg';
import logo_deramplus from 'images/company-logo/logo-deramplus.svg';
import logo_workflex from 'images/company-logo/logo-workflex.svg';

import logo_kbs from 'images/company-logo/logo-kbs.svg';
import logo_kb from 'images/company-logo/logo-kb.svg';
import logo_dl from 'images/company-logo/logo-dl.svg';
import logo_lotte from 'images/company-logo/logo-lotte.svg';
import logo_lottemembers from 'images/company-logo/logo-lottemembers.svg';
import logo_lottedepartment from 'images/company-logo/logo-lottedepartment.svg';
import logo_lottechilsung from 'images/company-logo/logo-lottechilsung.svg';
import logo_lotteventures from 'images/company-logo/logo-lotteventures.svg';

import logo_danbi from 'images/company-logo/logo-danbi.svg';
import logo_choa from 'images/company-logo/logo-choa.svg';
import logo_hunet from 'images/company-logo/logo-hunet.svg';

import logo_lottehotels from 'images/company-logo/logo-lottehotels.svg';
import logo_seoulgarden from 'images/company-logo/logo-seoulgarden.svg';
import logo_handys from 'images/company-logo/logo-handys.svg';
import logo_trustay from 'images/company-logo/loto-trustay.svg';
import logo_xi from 'images/company-logo/logo-xi.svg';

import logo_fas from 'images/company-logo/logo-fas.svg';
import logo_resortfitness from 'images/company-logo/logo-resortfitness.svg';

import logo_spotify from 'images/company-logo/logo-spotify.svg';
import logo_zara from 'images/company-logo/logo-zara.svg';
import logo_stradvision from 'images/company-logo/logo-stradvision.svg';
import logo_naver from 'images/company-logo/logo-naver.svg';
import logo_security from 'images/company-logo/logo-security.svg';
import logo_abib from 'images/company-logo/logo-abib.svg';
import logo_sports from 'images/company-logo/logo-sports.svg';
import logo_mindcafe from 'images/company-logo/logo-mindcafe.svg';
import logo_purplelabs from 'images/company-logo/logo-purplelabs.svg';
import logo_luxurtall from 'images/company-logo/logo-luxurtall.svg';
import logo_datadog from 'images/company-logo/logo-datadog.svg';
import logo_pnpt from 'images/company-logo/logo-pnpt.svg';

const Partner = () => {
  return (
    <Stack
      sx={{
        maxWidth: 1260,
        margin: 'auto',
        width: '88%',
        p: {
          md: '120px 0',
          xs: '50px 0'
        }
      }}
    >
      <Stack
        flexDirection="column"
        sx={{
          mb: { md: '30px' }
        }}
      >
        <Typography
          sx={{
            fontSize: { md: 32, xs: 20 },
            fontWeight: 'bold',
            letterSpacing: '0.5px'
          }}
        >
          워커스하이는{' '}
          <Hidden mdUp>
            <br />
          </Hidden>{' '}
          업계의 리더 기업들과 함께합니다.
        </Typography>
        <Typography
          sx={{
            fontSize: { md: 24, xs: 14 },
            fontWeight: 400,
            color: '#c1c1c1',
            lineHeight: { md: 2.5, xs: '22px' },
            m: { md: 0, xs: '12px 0 30px 0' },
            wordBreak: 'keep-all',
            letterSpacing: '0.5px'
          }}
        >
          1만 7천명의 회원과 100여개 기업이 워커스하이와 함께합니다.
        </Typography>
      </Stack>
      <PartnerComponent
        title="공유 OFFICE"
        logoList={[logo_wework, logo_justco, logo_deramplus, logo_workflex]}
      />
      <PartnerComponent
        title="대기업"
        logoList={[
          logo_kbs,
          logo_kb,
          logo_dl,
          logo_lotte,
          logo_lottemembers,
          logo_lottedepartment,
          logo_lottechilsung,
          logo_lotteventures
        ]}
      />
      <PartnerComponent
        title="오피스"
        logoList={[logo_danbi, logo_choa, logo_hunet]}
      />
      <PartnerComponent
        title="호텔 / 주거"
        logoList={[
          logo_lottehotels,
          logo_seoulgarden,
          logo_handys,
          logo_trustay,
          logo_xi
        ]}
      />
      <PartnerComponent
        title="스포츠"
        logoList={[logo_fas, logo_resortfitness]}
      />
      <PartnerComponent
        title="글로벌 / 스타트업"
        logoList={[
          logo_security,
          logo_abib,
          logo_sports,
          logo_spotify,
          logo_zara,
          logo_stradvision,
          logo_naver,
          logo_mindcafe,
          logo_purplelabs,
          logo_luxurtall,
          logo_datadog,
          logo_pnpt
        ]}
      />
    </Stack>
  );
};

const PartnerComponent = ({ title, logoList }) => {
  return (
    <Stack
      sx={{
        flexDirection: { md: 'row', xs: 'column' },
        width: '100%',
        '&:last-child': {
          borderBottom: '1px solid #d3d6e4'
        }
      }}
      borderTop="1px solid #d3d6e4"
      p="15px 0"
      minHeight={{ md: '140px', xs: 'initial' }}
    >
      <Stack sx={{ width: { md: '24%', xs: '100%' } }} mb="12px">
        <Typography
          sx={{
            fontSize: { md: 26, xs: 14 },
            fontWeight: 500,
            pl: '10px',
            letterSpacing: '0.5px'
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: { md: '76%', xs: '100%' }
        }}
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
      >
        {logoList.map((url, index) => (
          <Stack
            sx={{
              width: { md: '20%', xs: '25%' },
              height: 'auto',
              p: { md: '0 0 0 10px', sm: '0 20px 0 10px', xs: '0 3px 0 10px' },
              justifyContent: 'center',
              mt: { md: index > 4 && '50px', xs: index >= 4 && '18px' }
            }}
          >
            <img src={url} alt="logo" />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Partner;
