import { Stack, Typography } from '@mui/material';
import React from 'react';
import './Selection.css';
import { makeStyles } from '@mui/styles';

import img1 from 'images/sub2/sub2_sect7_img1.png';
import img2 from 'images/sub2/sub2_sect7_img2.png';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: '30px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '15px',
      height: '270px',
      objectFit: 'cover'
    }
  }
}));

export const Selection = ({ id }) => {
  return (
    <Stack>
      <div className="bgSelect" />
      <Stack bgcolor="#f5f5f5" p={{ md: '120px 0', xs: '30px 0' }}>
        <Stack
          sx={{
            maxWidth: '1260px',
            margin: 'auto',
            width: '88%'
          }}
        >
          <Stack
            sx={{
              flexDirection: {
                md: 'row',
                xs: 'column'
              },
              gap: { md: 2, xs: 5 }
            }}
          >
            <Stack sx={{ width: { md: '50%', xs: '100%' } }}>
              <Typography
                sx={{ fontSize: { md: 36, xs: 20 }, fontWeight: 'bold' }}
              >
                <span>
                  가장 진화된 스낵복지
                  <br />
                  OFFRESH만의 차별점
                </span>
              </Typography>
            </Stack>
            <Stack sx={{ width: { md: '50%', xs: '100%' } }}>
              <Stack
                p={{ md: '0 0 40px 0', xs: '0 0 18px 0' }}
                borderBottom="1px solid #111"
              >
                <Typography fontSize={{ md: 28, xs: 18 }} fontWeight={700}>
                  Well-planned
                </Typography>
                <Typography
                  fontSize={{ md: 28, xs: 18 }}
                  color="#777"
                  fontWeight={700}
                  m={{ md: '10px 0 20px 0', xs: '5px 0 7px 0' }}
                >
                  Fit
                </Typography>
                <Typography
                  fontSize={{ md: 24, xs: 14 }}
                  color="#555"
                  letterSpacing="0.5px"
                >
                  오피스 인프라를 분석한 최적의 큐레이션 서비스
                </Typography>
              </Stack>
              <Stack
                p={{ md: '40px 0', xs: '18px 0' }}
                borderBottom="1px solid #111"
              >
                <Typography fontSize={{ md: 28, xs: 18 }} fontWeight={700}>
                  Thoughtful
                </Typography>
                <Typography
                  fontSize={{ md: 28, xs: 18 }}
                  color="#777"
                  fontWeight={700}
                  m={{ md: '10px 0 20px 0', xs: '5px 0 7px 0' }}
                >
                  Office Life Style Solution
                </Typography>
                <Typography
                  fontSize={{ md: 24, xs: 14 }}
                  color="#555"
                  letterSpacing="0.4px"
                  sx={{ wordBreak: 'keep-all' }}
                >
                  임직원들이 필요로 하는 상품과 서비스를 고찰하는 오피스 라이프
                  솔루션
                </Typography>
              </Stack>
              <Stack p={{ md: '40px 0 0 0', xs: '18px 0 0 0' }}>
                <Typography fontSize={{ md: 28, xs: 18 }} fontWeight={700}>
                  Selective
                </Typography>
                <Typography
                  fontSize={{ md: 28, xs: 18 }}
                  color="#777"
                  fontWeight={700}
                  m={{ md: '10px 0 20px 0', xs: '5px 0 7px 0' }}
                >
                  Premium
                </Typography>
                <Typography
                  fontSize={{ md: 24, xs: 14 }}
                  color="#555"
                  letterSpacing="0.4px"
                  sx={{ wordBreak: 'keep-all' }}
                >
                  AI분석을 기반으로 선별된 제품라인으로 큐레이션된 프리미엄
                  셀력션
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack p={{ md: '100px 0', xs: '70px 0 50px 0' }} id={id}>
        <Stack
          sx={{
            maxWidth: '1260px',
            margin: 'auto',
            width: '88%'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column'
            }}
          >
            <Stack>
              <Typography
                sx={{ fontSize: { md: 36, xs: 20 }, fontWeight: 'bold' }}
                mb={{ md: '60px', xs: '30px' }}
              >
                <span>OFFRESH TYPE 소개</span>
              </Typography>
            </Stack>
            <Stack
              flexDirection={{ md: 'row', xs: 'column' }}
              gap={{ md: 7, xs: 4.5 }}
            >
              {[
                {
                  src: img1,
                  title: '무상복지 TYPE',
                  desc: '인원 대비 예산 정책이 명확할 때 추천 드려요'
                },
                {
                  src: img2,
                  title: '예산 배분 TYPE',
                  desc: '인원 대비 예산 정책이 명확하지 않을 때 추천드려요'
                }
              ].map(({ src, title, desc }) => (
                <Stack data-aos="fade-up" data-aos-delay="300">
                  <SelectionCard src={src} title={title} desc={desc} />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const SelectionCard = ({ src, title, desc }) => {
  const classes = useStyles();
  return (
    <Stack flexDirection="column" width="100%">
      <Stack sx={{ height: { md: '100%', xs: '270px' } }}>
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Stack>
        <Typography
          color="black"
          sx={{
            fontSize: { md: 28, xs: 16 },
            fontWeight: 'bold'
          }}
          m={{ xs: '20px 0 5px 0' }}
          textAlign={{ md: 'none', xs: 'left' }}
        >
          {title}
        </Typography>
        <Typography
          color="#555"
          sx={{ fontSize: { md: 20, xs: 13 } }}
          fontWeight={400}
          textAlign={{ md: 'none', xs: 'left' }}
          letterSpacing="0.5px"
        >
          {desc}
        </Typography>
      </Stack>
    </Stack>
  );
};
