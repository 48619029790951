import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MyLoadingButton from '@components/MyLoadingButton';

export default function MyDialog({
  open,
  setOpen,
  title = 'Your Title',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  sx,
  subTitle,
  disabled,
  onClose,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: '12px',
          width: isAlert ? '280px' : 'unset',
          minWidth: '280px',
          maxWidth: '90vw',
          p: isAlert ? '30px 25px 20px' : '30px',
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#343434', opacity: '0.6 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: 'text.primary',
          fontSize: isAlert ? '15px !important' : '18px !important',
          textAlign: 'center',
          py: 0,
          px: isAlert ? 0 : 6,
          whiteSpace: isAlert ? 'wrap' : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          mb: isAlert ? 0 : '20px'
        }}
      >
        {title}
        {!isAlert && hasCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 22,
              top: 22,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {subTitle && typeof subTitle === 'function' ? (
          subTitle
        ) : (
          <Typography sx={{ fontSize: 13 }}>{subTitle}</Typography>
        )}
      </DialogTitle>
      {children && (
        <DialogContent sx={{ p: 0, mt: '10px' }}>{children}</DialogContent>
      )}
      {!hideDialogButton && (
        <DialogActions sx={{ justifyContent: 'center', mt: '30px', p: 0 }}>
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: '44px',
                minWidth: '110px',
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || t('cancel')}
            </Button>
          )}
          <MyLoadingButton
            onClick={onOk || handleClose}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px',
              flex: isAlert ? 1 : 'unset'
            }}
            disabled={disabled}
          >
            {okTitle || t('check')}
          </MyLoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
