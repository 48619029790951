/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React from 'react';
import './SwiperButton.css';

const SwiperButton = ({ type, disabled, swiper }) => {
  const handleNextButtonClick = () => {
    if (swiper !== null && !disabled) {
      swiper.slideNext();
    }
  };
  const handlePrevButtonClick = () => {
    if (swiper !== null && !disabled) {
      swiper.slidePrev();
    }
  };
  const handleClick = () => {
    if (type === 'next') handleNextButtonClick();
    if (type === 'prev') handlePrevButtonClick();
  };
  return (
    <div
      className={`swiper-button-${type} ${
        disabled ? 'swiper-button-disabled' : null
      }`}
      onClick={handleClick}
    />
  );
};

export default SwiperButton;
