import React from 'react';
import { Stack, Typography } from '@mui/material';
import IntroCard from '@components/cards/IntroCard';

const Category = () => {
  return (
    <Stack
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        width: '88%'
      }}
    >
      <Stack
        sx={{
          flexDirection: {
            md: 'row',
            xs: 'column'
          },
          gap: 2
        }}
      >
        <Stack sx={{ width: { md: '40%', xs: '100%' } }} data-aos="fade-up">
          <Typography sx={{ fontSize: { md: 32, xs: 20 }, fontWeight: 'bold' }}>
            <span>
              공간과 고객에 집중한 <br /> 맞춤형 리테일 플랫폼을 <br />
              구축합니다.
            </span>
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          sx={{ width: { md: '60%', xs: '100%' }, flexWrap: 'wrap' }}
        >
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '0 20px 20px 0', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              bgColor="#F6FAFF"
              title="Accessibility for customers"
              desc="더욱 가까운 접근성"
              content="오피스, 호텔, 스포츠 시설 등 고객이 머무는 곳에서 공간을 이탈없이 원하는 상품을 간편하게 구매 할 수 있도록 합니다."
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '0 0px 20px 20px', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              bgColor="#F5F5F5"
              title="Curated Selections"
              desc={
                <span>
                  정교화된 <br /> 상품 큐레이션
                </span>
              }
              content="소비자들의 구매행동 패턴과 데이터들을 기반으로 선별된 상품들을 추천합니다."
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '20px 20px 0px 0px', xs: '0 0 15px 0' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              title="Smart & Easy"
              desc="쉽고 간편한 이용방식"
              content="사원증이나 키카드를 이용하여구매부터 결제까지 한 번에 이용 가능한 간편 주문 방식을 설계합니다."
              sx={{ backgroundColor: { xs: '#F6FAFF', md: '#F5F5F5' } }}
            />
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' },
              p: { md: '20px 0px 0px 20px' }
            }}
            data-aos="fade-up"
          >
            <IntroCard
              title="Bespoke system"
              desc={
                <span>
                  공간 맞춤형 <br /> 프리미엄 외관
                </span>
              }
              content="전담 매니저를 배정하여 설치될 공간 인프라를 구축하고 맞춤형 외관을 제작합니다."
              sx={{ backgroundColor: { md: '#F6FAFF', xs: '#F5F5F5' } }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Category;
