/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Stack } from '@mui/material';
import FeedBack from '@pages/components/FeedBack';
import ScrollTop from '@components/buttons/ScrollTop';
import QuickMenu from '@components/buttons/QuickMenu';
import Partner from '@pages/components/Partner';
import Application from '@pages/Offesh2/Application';
import Menu from './Menu';
import { Banner } from './Banner';
import Category from './Category';
import { Service } from './Service';
import { IT } from './IT';

import './index.css';
import { BGABC } from './BGABC';
import { Selection } from './Selection';
import { Introductory } from './Introductory';
import { Bespoke } from './Bespoke';
import { Various } from './Various';
import { Map } from './Map';
import { MarqueeComponent } from './Marquee';

const Offresh2 = () => {
  AOS.init();
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);
  const handleScrollTo = (idx) => {
    const nav = document.getElementById('menu');
    const component1 = document.getElementById('page_tab');
    const component2 = document.getElementById('scrollTo2');
    const component3 = document.getElementById('scrollTo3');
    if (idx === 1) {
      window.scrollTo({
        top: component1.offsetTop,
        behavior: 'smooth'
      });
    }
    if (idx === 2) {
      window.scrollTo({
        top: component2.offsetTop - nav.offsetHeight / 2,
        behavior: 'smooth'
      });
    }
    if (idx === 3) {
      window.scrollTo({
        top: component3.offsetTop - nav.offsetHeight,
        behavior: 'smooth'
      });
    }
  };
  const [idxNav, setIdxNav] = useState(0);
  useEffect(() => {
    const nav = document.getElementById('menu');
    const component2 = document.getElementById('scrollTo2');
    const component3 = document.getElementById('scrollTo3');
    const handleScroll = () => {
      const top = window.pageYOffset;
      if (top >= component2.offsetTop - nav.offsetHeight) {
        setIdxNav(1);
      }
      if (top >= component3.offsetTop - nav.offsetHeight) {
        setIdxNav(2);
      }
      if (top < component2.offsetTop - nav.offsetHeight) {
        setIdxNav(0);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Stack
      sx={{
        // maxWidth: '100%',
        margin: 'auto',
        backgroundColor: 'white',
        pt: {
          md: '80px',
          xs: '40px'
        }
      }}
    >
      <Menu onClick={handleScrollTo} idxNav={idxNav} />
      <Banner />
      <Category />
      <Service />
      <IT />
      <Selection id="scrollTo2" />
      <Introductory />
      <Bespoke />
      <Various />
      <Application id="scrollTo3" />
      <BGABC />
      <Map />
      <MarqueeComponent />
      <Partner />
      <FeedBack />
      <QuickMenu />
      <ScrollTop />
    </Stack>
  );
};

export default Offresh2;
