import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { isNumber } from '@utils/helpers';
import { CancelRounded } from '@mui/icons-material';

const MyTextField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  errMg,
  fullWidth,
  required,
  endComponent,
  disabled = false,
  onKeyDown,
  onClear,
  ...rest
}) => {
  const [openClear, setOpenClear] = useState(false);
  return (
    <Stack
      width={fullWidth && '100%'}
      sx={{
        flexDirection: { md: 'row', xs: 'column' },
        alignItems: { md: 'baseline' }
      }}
    >
      {label && (
        <Typography
          sx={{
            fontSize: { md: 20, xs: 14 },
            fontWeight: 500,
            width: '100px',
            mr: 3
          }}
        >
          {required && (
            <span style={{ color: 'red', fontSize: { md: 20, xs: 14 } }}>
              *
            </span>
          )}
          {label}
        </Typography>
      )}
      <Stack flexDirection="column" width={fullWidth && '100%'}>
        <TextField
          disabled={disabled}
          error={errMg}
          onFocus={() => {
            setOpenClear(true);
          }}
          onBlur={() =>
            setTimeout(() => {
              setOpenClear(false);
            }, 200)
          }
          onPaste={(e) => {
            const value = e.clipboardData.getData('text');
            if (onKeyDown) {
              if (!isNumber(value.replace(/,/g, ''))) {
                e.preventDefault();
              }
            }
          }}
          onKeyDown={onKeyDown}
          autoComplete="off"
          name={name}
          value={value}
          onChange={onChange}
          variant="standard"
          placeholder={placeholder}
          InputProps={{
            endAdornment:
              endComponent ||
              (value && (
                <InputAdornment
                  position="end"
                  sx={{
                    visibility: !openClear && 'hidden'
                  }}
                >
                  <IconButton onClick={onClear}>
                    <CancelRounded
                      sx={{
                        fontSize: { md: '36px', xs: '24px' },
                        color: 'lightgrey'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ))
          }}
          sx={{
            '.MuiInputBase-input': {
              p: { md: '15px 40px 15px 15px', xs: '10px 28px 10px 4px' },
              fontSize: {
                md: 20,
                xs: 14
              }
            }
          }}
          {...rest}
        />
      </Stack>
    </Stack>
  );
};

export default MyTextField;
