import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';

const Content = ({ headerHeight, footerHeight = 60, drawerWidth, open }) => {
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        minHeight: '100vh',
        width: `calc(100vw - ${open ? drawerWidth : 0}px)`
      }}
    >
      <Toolbar sx={{ height: `${headerHeight}px` }} />
      <Box
        component="main"
        sx={{ p: '30px' }}
        // style={{ maxHeight: `calc(100vh - ${150}px)` }}
      >
        <Outlet />
      </Box>
      <Toolbar sx={{ height: `${footerHeight}px` }} />
    </Box>
  );
};

export default Content;
