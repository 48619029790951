import { Stack, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import img1 from 'images/inquiry/inquiry_img9.svg';
import img2 from 'images/inquiry/inquiry_img7.svg';
import img3 from 'images/inquiry/inquiry_img8.svg';
import img4 from 'images/inquiry/inquiry_img20.svg';
import img5 from 'images/inquiry/inquiry_img15.svg';
import img6 from 'images/inquiry/inquiry_img21.svg';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '135px',
    [theme.breakpoints.down('md')]: {
      height: '54px'
    }
  }
}));

export const Various = () => {
  const listItems = [
    { src: img1, title: '음료' },
    { src: img2, title: '스낵' },
    { src: img3, title: '간편식' },
    { src: img4, title: '베이커리' },
    { src: img5, title: '건강' },
    { src: img6, title: '샐러드' }
  ];
  return (
    <Stack
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        width: '88%',
        pb: { md: '150px', xs: '50px' }
      }}
    >
      <Stack
        sx={{
          flexDirection: {
            md: 'row',
            xs: 'column'
          },
          gap: { md: 2, xs: 4 }
        }}
      >
        <Stack sx={{ width: { md: '30%', xs: '100%' } }}>
          <Typography
            sx={{
              fontSize: { md: 36, xs: 20 },
              fontWeight: 'bold',
              letterSpacing: '0.5px'
            }}
          >
            <span>
              OFFRESH의 <br />
              다양한 상품라인업
            </span>
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          sx={{ width: { md: '70%', xs: '100%' }, flexWrap: 'wrap' }}
          //   gap={{ md: 4, xs: 2 }}
        >
          {listItems.map(({ src, title }, index) => (
            <Stack data-aos="fade-up" width="33%">
              <VariousCard src={src} title={title} index={index} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const VariousCard = ({ src, title, index }) => {
  const classes = useStyles();
  const m1 = { md: '0 25px 25px 0', xs: '0 7px 7px 0' };
  const m2 = { md: '0 25px 25px 25px', xs: '0 7px 7px 7px' };
  const m3 = { md: '0 0 25px 25px', xs: '0 0 7px 7px' };
  const m4 = { md: '25px 25px 0 0', xs: '7px 7px 0 0' };
  const m5 = { md: '25px 25px 0 25px', xs: '7px 7px 0 7px' };
  const m6 = { md: '25px 0 0 25px', xs: '7px 0 0 7px' };
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor={index % 2 === 0 ? '#F6FAFF' : '#F5F5F5'}
      sx={{
        height: { md: '246px', xs: '98px' },
        width: 'auto',
        borderRadius: { md: '30px', xs: '15px' },
        m:
          (index + 1 === 1 && m1) ||
          (index + 1 === 2 && m2) ||
          (index + 1 === 3 && m3) ||
          (index + 1 === 4 && m4) ||
          (index + 1 === 5 && m5) ||
          m6
      }}
    >
      <Stack>
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Typography fontSize={{ md: 18, xs: 12 }} fontWeight={700}>
        {title}
      </Typography>
    </Stack>
  );
};
