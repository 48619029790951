import { Stack, Typography } from '@mui/material';
import React from 'react';

const IntroCard = (props) => {
  const { bgColor = 'white', title, desc, content, sx } = props;
  return (
    <Stack
      flexDirection="column"
      bgcolor={bgColor}
      sx={{
        height: { md: '340px', xs: '150px' },
        borderRadius: { md: '30px', xs: '15px' },
        p: { md: 5, xs: '20px 18px' },
        ...sx
      }}
    >
      <Typography color="primary" sx={{ fontSize: { md: 20, xs: 12 } }}>
        {title}
      </Typography>
      <Typography
        color="black"
        sx={{
          fontSize: { md: 28, xs: 16 },
          fontWeight: 'bold',
          m: { md: '5px 0 10px 0', xs: '3px 0 7px 0' }
        }}
      >
        {desc}
      </Typography>
      <Typography
        color="#555"
        sx={{
          fontSize: { md: 20, xs: 13 },
          wordBreak: 'keep-all'
          // letterSpacing: '0.01px'
        }}
      >
        {content}
      </Typography>
    </Stack>
  );
};

export default IntroCard;
