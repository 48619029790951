import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';

import { makeStyles } from '@mui/styles';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './IT.css';
import { Pagination, Autoplay } from 'swiper';

import img1 from 'images/sub2/sub2_img1.png';
import img2 from 'images/sub2/sub2_img2.png';
import img3 from 'images/sub2/sub2_img3.png';
import img4 from 'images/sub2/sub2_img4.png';
import img5 from 'images/sub2/sub2_img5.png';
import img6 from 'images/sub2/sub2_img6.png';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: '30px',
    height: '300px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '15px',
      height: '270px',
      width: '100%'
    }
  }
}));

export const IT = () => {
  return (
    <Stack>
      <Stack
        bgcolor="white"
        sx={{
          width: '100%',
          pb: { md: '100px', xs: '40px' }
        }}
      >
        <Stack
          sx={{
            maxWidth: '1260px',
            margin: 'auto',
            width: '88%'
          }}
        >
          <Typography
            textAlign={{ md: 'left', xs: 'left' }}
            m={{ md: '92px 0', xs: '30px 0' }}
            fontSize={{ md: 36, xs: 20 }}
            fontWeight="bold"
            letterSpacing="0.5px"
          >
            ‘워커스하이는 IT 기술을 통해 <br />
            복지 서비스에 최적화된 사내 식음료 서비스를 제공합니다.
          </Typography>
          <Hidden mdDown>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              gap="3%"
            >
              {[
                { title: '사원증 기반의 개인식별 및 관리', src: img1 },
                { title: '자유로운 결제방식 커스텀', src: img2 },
                { title: 'AI기반의 자동 발주/관리', src: img3 },
                { title: '안정적 공급망 & 저렴한 가격', src: img4 },
                { title: '비스포크형 맞춤 제작 진열장', src: img6 },
                {
                  title: (
                    <span>
                      확실한 사후관리
                      <Hidden mdDown>
                        <br />
                        배송/진열 및 정돈까지
                      </Hidden>
                    </span>
                  ),
                  src: img5
                }
              ].map(({ title, src }, index) => (
                <Stack width="31%" height="400px">
                  <ITCard title={title} src={src} index={index} />
                </Stack>
              ))}
            </Stack>
          </Hidden>
          <Hidden mdUp>
            <Swiper
              loop
              autoplay={{
                delay: 2000, // Thời gian trễ giữa các slide (ms)
                disableOnInteraction: false // Cho phép tự động phát khi người dùng tương tác
              }}
              slidesPerView={1}
              pagination={{
                clickable: true
              }}
              className="swiper-it"
              modules={[Pagination, Autoplay]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40
                }
              }}
            >
              {[
                { title: '사원증 기반의 개인식별 및 관리', src: img1 },
                { title: '자유로운 결제방식 커스텀', src: img2 },
                { title: 'AI기반의 자동 발주/관리', src: img3 },
                { title: '안정적 공급망 & 저렴한 가격', src: img4 },
                { title: '비스포크형 맞춤 제작 진열장', src: img6 },
                { title: '확실한 사후관리 배송', src: img5 }
              ].map(({ title, src }, index) => (
                <SwiperSlide>
                  <ITCard title={title} src={src} index={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Hidden>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ITCard = ({ src, title }) => {
  const classes = useStyles();
  return (
    <Stack flexDirection="column" width="100%">
      <Stack sx={{ height: { md: '100%', xs: '270px' } }}>
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Stack>
        <Typography
          color="black"
          sx={{
            fontSize: { md: 28, xs: 14 },
            fontWeight: 'bold'
          }}
          m={{ xs: '20px 0 0 0' }}
          textAlign="center"
        >
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};
