import { Button, Hidden, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import './Banner.css';

import icon from 'images/icon/icon-talk.svg';
import { useDispatch } from 'react-redux';
import { setInquiry } from '@store/reducers/appReducer';

export const Banner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      document
        .querySelectorAll('.line_effect_area .line_effect')
        .forEach((element) => {
          element.classList.add('on');
        });
    }, 500);
    // const handleScroll = () => {
    //   const winST = document.documentElement.scrollTop;
    //   const lineST = document.querySelector('.sect3').offsetTop;
    //   if (winST >= lineST - 700) {
    //     document
    //       .querySelectorAll('.line_effect_area .line_effect')
    //       .forEach((element) => {
    //         element.classList.add('on');
    //       });
    //   } else {
    //     document
    //       .querySelectorAll('.line_effect_area .line_effect')
    //       .forEach((element) => {
    //         element.classList.remove('on');
    //       });
    //   }
    // };
    // // Đăng ký sự kiện scroll khi component được mount
    // window.addEventListener('scroll', handleScroll);

    // // Hủy đăng ký sự kiện khi component bị unmount
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);

  const handleInquiry = () => {
    dispatch(setInquiry(true));
  };

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      sx={{
        maxWidth: '1260px',
        margin: 'auto',
        pb: {
          md: '80px',
          xs: '20px'
        },
        width: '88%',
        wordBreak: 'keep-all'
      }}
    >
      <Stack
        m={{ md: '50px 0 40px 0', xs: '50px 0 40px 0' }}
        sx={{ width: '100%' }}
      >
        <Typography
          fontSize={{ md: 48, xs: 26 }}
          fontWeight={700}
          textAlign="center"
          letterSpacing="1px"
        >
          오피스에서 경험하는
          <br />
          리프레시 서비스
        </Typography>
        <Typography
          m={{ md: '36px auto 0', xs: '8px auto 0' }}
          fontSize={{ md: 24, xs: 14 }}
          textAlign="center"
          color="#333"
          sx={{ maxWidth: '900px', letterSpacing: '0.4px' }}
        >
          Offresh는 Office와 Refresh를 합친 단어로서 오피스 공간에서 최상의
          리프레시를 경험하실 수 있는 맞춤형 복지 서비스를 제공합니다.
        </Typography>
        <Stack className="sect3" m={{ md: '40px 0', xs: '15px 0' }}>
          <div className="line_effect_area">
            <div className="line_effect" />
          </div>
        </Stack>
        <div className="cont_img" data-aos="fade-up" data-aos-delay="500" />
        <Hidden mdUp>
          <Stack flexDirection="row" justifyContent="center">
            <Button
              onClick={handleInquiry}
              startIcon={
                <img src={icon} alt="img" style={{ height: '22px' }} />
              }
              sx={{ bgcolor: '#111', color: 'white', mt: '50px' }}
            >
              무료 상담 하기
            </Button>
          </Stack>
        </Hidden>
      </Stack>
    </Stack>
  );
};
