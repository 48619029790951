import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';

import img1 from 'images/sub2/sub2_sect9_img1.png';
import img2 from 'images/sub2/sub2_sect9_img2.png';
import img3 from 'images/sub2/sub2_sect9_img3.png';

import { makeStyles } from '@mui/styles';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './Bespoke.css';
import { Pagination, Autoplay } from 'swiper';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: '30px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      borderRadius: '15px',
      height: '270px',
      width: 'auto'
    }
  }
}));

export const Bespoke = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        pb: { md: '100px', xs: '40px' }
      }}
    >
      <Stack
        sx={{
          maxWidth: '1260px',
          margin: 'auto',
          width: '88%'
        }}
      >
        <Typography
          textAlign="left"
          m={{ md: '92px 0', xs: '30px 0' }}
          fontSize={{ md: 36, xs: 20 }}
          fontWeight="bold"
          letterSpacing="0.5px"
        >
          OFFRESH ONLY{' '}
          <Hidden mdDown>
            <br />
          </Hidden>{' '}
          오프레쉬만의 BESPOKE (소비자 맞춤형 디자인) SERVICE
        </Typography>
        <Hidden mdDown>
          <Stack flexDirection="row" alignItems="center" width="100%" gap={4}>
            {[
              { title: '키오스크 광고 영역', src: img1 },
              { title: '기업 로고 사이니지', src: img2 },
              { title: '맞춤 제작', src: img3 }
            ].map(({ title, src }) => (
              <Stack width="33%" data-aos="fade-up">
                <BespokeCard title={title} src={src} />
              </Stack>
            ))}
          </Stack>
        </Hidden>
        <Hidden mdUp>
          <Swiper
            loop
            autoplay={{
              delay: 2000, // Thời gian trễ giữa các slide (ms)
              disableOnInteraction: false // Cho phép tự động phát khi người dùng tương tác
            }}
            centeredSlides
            spaceBetween={20}
            slidesPerView={1}
            pagination={{
              clickable: true
            }}
            className="swiper-bespoke-2"
            modules={[Autoplay, Pagination]}
            breakpoints={{
              1400: {
                spaceBetween: 70,
                pagination: false
              },
              1024: {
                pagination: false
              }
            }}
          >
            {[
              { title: '키오스크 광고 영역', src: img1 },
              { title: '기업 로고 사이니지', src: img2 },
              { title: '맞춤 제작', src: img3 }
            ].map(({ title, src }) => (
              <SwiperSlide className="swiper-slide-bespoke-2">
                <BespokeCard title={title} src={src} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Hidden>
      </Stack>
    </Stack>
  );
};

const BespokeCard = ({ src, title }) => {
  const classes = useStyles();
  return (
    <Stack flexDirection="column" width="100%">
      <Stack sx={{ height: { md: '100%', xs: '270px' } }}>
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Typography
        color="black"
        sx={{
          fontSize: { md: 28, xs: 14 },
          fontWeight: 'bold'
        }}
        m={{ md: '15px 0 30px 0', xs: '15px 0 30px 0' }}
        textAlign="center"
      >
        {title}
      </Typography>
    </Stack>
  );
};
