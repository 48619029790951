import { WELFARE_MAX_POINT } from '@constants/utils';
import i18n from './i18n';

export default function error2Text(error) {
  if (!error?.keyError)
    return {
      title: i18n.t('error.serverError.title'),
      content: i18n.t('error.serverError.content')
    };
  switch (error.keyError) {
    case 'ADMIN_WRONG_PASSWORD':
      return {
        title: i18n.t(`error.${error.keyError}.title`, {
          times: error?.message
        }),
        content: i18n.t(`error.${error.keyError}.content`)
      };
    case 'MINIBAR_NOT_ENOUGH_STOCK_QUANTITY_FOR_EQUIPMENT':
      return {
        title: i18n.t(`error.${error.keyError}.title`),
        content: i18n.t(`error.${error.keyError}.content`, {
          items:
            error?.fieldErrors && Object.values(error?.fieldErrors).join(', ')
        })
      };
    case 'WELFARE_POINT_MAXIMUM':
      return {
        title: i18n.t(`error.${error.keyError}.title`),
        content: i18n.t(`error.${error.keyError}.content`, {
          max_points: new Intl.NumberFormat('en-CA').format(WELFARE_MAX_POINT)
        })
      };
    case 'APP_LINK_NOT_EXIST':
      return {
        title: i18n.t(`error.${error.keyError}.title`),
        content: i18n.t(`error.${error.keyError}.content`)
      };
    case 'ADMIN_NOT_EXIST_USERNAME':
    case 'ADMIN_LOCK_ACCOUNT_TRY_PASSWORD_5_TIMES':
    case 'NOT_ALLOW_SELF_DELETE':
    case 'ADMIN_USERNAME_EXISTED':
    case 'ADMIN_NOT_MATCH_PASSWORD':
    case 'UNAUTHORIZED':
    case 'NOT_EXIST_DATA':
    case 'LIST_MINIBAR_CODE_NOT_EXIST':
    case 'CURATION_PRODUCT_EXIST':
    case 'CANNOT_ADD_PRODUCT':
    case 'CLIENT_ORDER_PRODUCT_EXISTS':
    case 'NOT_ALLOW_DELETE_ORDER':
    case 'ORDER_CANNOT_PICKING':
    case 'CANNOT_MODIFY_PRODUCT':
    default:
      return {
        title: i18n.t(`error.${error.keyError}.title`),
        content: i18n.t(`error.${error.keyError}.content`)
      };
  }
}
