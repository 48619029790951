import HelmetContainer from '@components/HelmetContainer';
import MyDialog from '@components/MyDialog';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import authServices from '@services/auth';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';

export const Account = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      currentPassword: null,
      newPassword: null,
      verifyPassword: null
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('필수의!'),
      newPassword: Yup.string().required('필수의!'),
      verifyPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], '비밀번호가 일치하지 않습니다')
        .required('필수의!')
    }),
    onSubmit: async (values) => {
      const { currentPassword, verifyPassword } = values;
      const { error: resError } = await authServices.changePassword({
        currentPassword,
        newPassword: verifyPassword
      });
      if (resError) dispatch(setError({ title: resError?.message }));
      else {
        setOpen(false);
        dispatch(setError({ title: '성공적으로 비밀번호 변경' }));
      }
    }
  });
  const onOk = () => {
    const el = document.getElementById('formik-btn');
    el.click();
  };
  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const rs = await authServices.getInfo();
    if (rs.data) {
      console.log(rs.data);
      setInfo(rs.data);
    }
  };
  useEffect(() => {
    getInfo();
  }, []);
  return (
    <HelmetContainer
      title={t('menusidebar.account')}
      content="Total order history page"
    >
      <Stack sx={{ width: '100%', margin: 'auto' }}>
        <Stack
          flexDirection="column"
          borderRadius="10px"
          boxShadow={12}
          p={{ md: '40px 60px 40px 60px' }}
        >
          <Typography
            color="primary"
            fontSize={{ md: 22 }}
            fontWeight={600}
            mb="20px"
          >
            정보
          </Typography>
          <Stack flexDirection="row" alignItems="center" width="100%">
            <Stack width="100%">
              <FieldContainer
                label="거래처명"
                value={
                  <Typography
                    fontWeight={600}
                    color="black"
                    fontSize={18}
                    fontFamily="Poppins"
                    letterSpacing="0.5px"
                  >
                    {info?.clientName}
                  </Typography>
                }
              />
            </Stack>
          </Stack>
          <Stack flexDirection="row" alignItems="center" width="100%">
            <Stack width="33.33%">
              <FieldContainer
                label="담당자 연락처"
                value={info?.clientContact}
              />
            </Stack>
            <Stack width="33.33%">
              <FieldContainer label="공식 이메일" value={info?.officialEmail} />
            </Stack>
            <Stack width="33.33%">
              <FieldContainer
                label="담당자 이메일"
                value={info?.chargerEmail}
              />
            </Stack>
          </Stack>
          <Stack flexDirection="row" alignItems="center" width="100%">
            <Stack width="100%">
              <FieldContainer
                label="주소"
                value={info?.clientAddress2 || info?.clientAddress1}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="flex-start"
            sx={{
              m: { md: '20px 0 0 0' }
            }}
          >
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                width: { md: '150px', height: '40px' }
              }}
            >
              비밀번호 변경
            </Button>
          </Stack>
        </Stack>
        <MyDialog
          sx={{ width: '400px' }}
          open={open}
          setOpen={setOpen}
          title="비밀번호 변경"
          hasCancelButton
          onOk={onOk}
          onClose={() =>
            setTimeout(() => {
              formik.resetForm();
            }, 200)
          }
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack flexDirection="column">
              <Stack m="10px 0">
                <TextField
                  error={
                    formik.errors.currentPassword &&
                    formik.touched.currentPassword
                  }
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  name="currentPassword"
                  type="password"
                  label="현재 비밀번호"
                />
                {formik.errors.currentPassword &&
                  formik.touched.currentPassword && (
                    <Typography sx={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.currentPassword}
                    </Typography>
                  )}
              </Stack>
              <Stack m="10px 0">
                <TextField
                  error={
                    formik.errors.newPassword && formik.touched.newPassword
                  }
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  name="newPassword"
                  type="password"
                  label="새 비밀번호"
                />
                {formik.errors.newPassword && formik.touched.newPassword && (
                  <Typography sx={{ fontSize: 12, color: 'red' }}>
                    {formik.errors.newPassword}
                  </Typography>
                )}
              </Stack>
              <Stack m="10px 0">
                <TextField
                  error={
                    formik.errors.verifyPassword &&
                    formik.touched.verifyPassword
                  }
                  onChange={formik.handleChange}
                  value={formik.values.verifyPassword}
                  name="verifyPassword"
                  type="password"
                  label="비밀번호 확인"
                />
                {formik.errors.verifyPassword &&
                  formik.touched.verifyPassword && (
                    <Typography sx={{ fontSize: 12, color: 'red' }}>
                      {formik.errors.verifyPassword}
                    </Typography>
                  )}
              </Stack>
            </Stack>
            <Stack sx={{ visibility: 'hidden' }}>
              <button id="formik-btn" type="submit">
                Submit
              </button>
            </Stack>
          </form>
        </MyDialog>
      </Stack>
    </HelmetContainer>
  );
};

const FieldContainer = ({ label, value }) => {
  return (
    <Stack flexDirection="row" alignItems="center" m="10px 0">
      <Stack sx={{ width: '140px' }}>
        <Typography
          fontWeight={500}
          color="rgb(85, 85, 85)"
          fontSize={14}
          fontStyle="italic"
          letterSpacing="0.5px"
        >
          {label}:
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontWeight={400}
          color="black"
          fontSize={14}
          fontFamily="Poppins"
          letterSpacing="0.5px"
        >
          {value || '___'}
        </Typography>
      </Stack>
    </Stack>
  );
};
