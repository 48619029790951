import { Hidden, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import './Introductory.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import SwiperButton from '@components/buttons/SwiperButton';
import img1 from 'images/sub2/sub2_sect8_img1.png';
import img2 from 'images/sub2/sub2_sect8_img2.png';
import img3 from 'images/sub2/sub2_sect8_img3.png';
import img4 from 'images/sub2/sub2_sect8_img4.png';
import img5 from 'images/sub2/sub2_sect8_img5.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    width: 'auto',
    height: '100% !important',
    objectFit: 'cover'
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '15px !important'
  }
}));

export const Introductory = () => {
  const listSlide1 = [
    {
      src: img1,
      title: '업종: 방송사',
      listInfo: [
        { titleI: '구성', content: '300명 이상' },
        { titleI: '월 예산', content: '10백만원 이하' },
        {
          titleI: (
            <span>
              관제 시스템{' '}
              <Hidden mdUp>
                <br />
              </Hidden>{' '}
              키오스크
            </span>
          ),
          content: '있음'
        },
        {
          titleI: '설비 타입',
          content: '냉장고 2대, 키오스크 1대, 하부 전자레인지장'
        },
        { titleI: '결제 방식', content: '사원증 태그 모델' }
      ]
    },
    {
      src: img2,
      title: '업종: 백화점 본사',
      listInfo: [
        { titleI: '구성', content: '500명 이상' },
        { titleI: '월 예산', content: '15백만원 이하' },
        {
          titleI: (
            <span>
              관제 시스템{' '}
              <Hidden mdUp>
                <br />
              </Hidden>{' '}
              키오스크
            </span>
          ),
          content: '있음'
        },
        { titleI: '설비 타입', content: '냉장고 2대, 상온장 1대' },
        { titleI: '결제 방식', content: '없음' }
      ]
    },
    {
      src: img3,
      title: '업종: 백화점 본사',
      listInfo: [
        { titleI: '구성', content: '50명 이상' },
        { titleI: '월 예산', content: '5백만원 이하' },
        {
          titleI: (
            <span>
              관제 시스템{' '}
              <Hidden mdUp>
                <br />
              </Hidden>{' '}
              키오스크
            </span>
          ),
          content: '있음'
        },
        { titleI: '설비 타입', content: '냉장고 1대, 키오스크 1대' },
        { titleI: '결제 방식', content: '사원증 태그 모델' }
      ]
    }
  ];
  const listSlide2 = [
    {
      src: img4,
      title: '업종: 글로벌 게임회사',
      listInfo: [
        { titleI: '구성', content: '400명 이상' },
        { titleI: '관제 시스템 키오스크', content: '있음' },
        {
          titleI: '설비 타입',
          content: '냉장고 2대, 키오스크 1대, 하부 상온장 1대'
        },
        {
          titleI: '결제 방식',
          content: '회사 60% 지원, 개인 카드 40% 결제'
        },
        { titleI: '정산 방식', content: '세일즈 리포트 전달 사후 정산' }
      ]
    },
    {
      src: img5,
      title: '업종: 유통사',
      listInfo: [
        { titleI: '구성', content: '400명 이상' },
        { titleI: '관제 시스템 키오스크', content: '있음' },
        {
          titleI: '설비 타입',
          content: '냉장고 2대, 키오스크 1대, 하부 상온장 1대'
        },
        { titleI: '결제 방식', content: '개인 카드 결제' },
        { titleI: '정산 방식', content: '세일즈 리포트 전달 사후 정산' }
      ]
    }
  ];
  const [swiper, setSwiper] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [tab, setTab] = useState(0);
  return (
    <Stack bgcolor="#f5f5f5" p={{ md: '120px 0', xs: '30px 0' }}>
      <Stack
        sx={{
          maxWidth: '1920px',
          margin: 'auto',
          width: '100%'
        }}
      >
        <Stack flexDirection="column" alignItems="center">
          <Typography
            fontSize={{ md: 48, xs: 24 }}
            fontWeight={700}
            mb={{ md: '60px', xs: '16px' }}
            letterSpacing="0.5px"
          >
            도입사례
          </Typography>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={{ md: 12, xs: 4 }}
            mb={{ md: '80px', xs: '30px' }}
          >
            <Typography
              letterSpacing="0.5px"
              fontSize={{ md: 28, xs: 16 }}
              fontWeight={500}
              color={tab === 0 ? '#111' : '#9C9C9C'}
              sx={{
                '&:hover': {
                  color: '#111',
                  cursor: 'pointer'
                }
              }}
              onClick={() => {
                setTab(0);
                setIndex(0);
                setIndex2(0);
              }}
            >
              무상복지 TYPE
            </Typography>
            <Typography
              letterSpacing="0.5px"
              fontSize={{ md: 28, xs: 16 }}
              fontWeight={500}
              color={tab === 1 ? '#111' : '#9C9C9C'}
              sx={{
                '&:hover': {
                  color: '#111',
                  cursor: 'pointer'
                }
              }}
              onClick={() => {
                setTab(1);
                setIndex(0);
                setIndex2(0);
              }}
            >
              예산 배분 TYPE
            </Typography>
          </Stack>
          <Stack
            sx={{ margin: 'auto', width: { md: '100%', xs: '88%' } }}
            position="relative"
          >
            <Stack
              sx={{
                position: 'absolute',
                left: 120,
                top: '50%',
                transform: 'translate(0, -50%)',
                zIndex: 1000,
                display: {
                  md: 'block',
                  xs: 'none'
                }
              }}
            >
              <SwiperButton
                type="prev"
                swiper={tab === 0 ? swiper : swiper2}
                disabled={tab === 1 ? index2 === 0 : index === 0}
              />
            </Stack>
            {tab === 0 && (
              <Swiper
                onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
                onActiveIndexChange={(index) => setIndex(index?.activeIndex)}
                centeredSlides
                spaceBetween={100}
                slidesPerView="auto"
                pagination={{
                  clickable: true
                }}
                className="swiper-introductory-2"
                modules={[Pagination]}
                breakpoints={{
                  1400: {
                    // spaceBetween: 70,
                    pagination: false
                  },
                  1024: {
                    pagination: false
                  }
                }}
              >
                {tab === 0 &&
                  listSlide1.map(({ src, title, listInfo }) => (
                    <SwiperSlide className="swiper-slide-introductory-2">
                      {({ isActive }) => (
                        <Stack
                          sx={{
                            opacity: {
                              md: isActive ? 1 : 0.2,
                              xs: isActive ? 1 : 0.2
                            }
                          }}
                          width="100%"
                          height="100%"
                        >
                          <IntroductoryCard
                            src={src}
                            title={title}
                            listInfo={listInfo}
                          />
                        </Stack>
                      )}
                    </SwiperSlide>
                  ))}
                {tab === 1 &&
                  listSlide2.map(({ src, title, listInfo }) => (
                    <SwiperSlide className="swiper-slide-introductory-2">
                      {({ isActive }) => (
                        <Stack
                          sx={{
                            opacity: {
                              md: isActive ? 1 : 0.2,
                              xs: isActive ? 1 : 0.2
                            }
                          }}
                          width="100%"
                          height="100%"
                        >
                          <IntroductoryCard
                            src={src}
                            title={title}
                            listInfo={listInfo}
                          />
                        </Stack>
                      )}
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
            {tab === 1 && (
              <Swiper
                onSwiper={(swiperInstance) => setSwiper2(swiperInstance)}
                onActiveIndexChange={(index) => setIndex2(index?.activeIndex)}
                centeredSlides
                spaceBetween={100}
                slidesPerView="auto"
                pagination={{
                  clickable: true
                }}
                className="swiper-introductory-2"
                modules={[Pagination]}
                breakpoints={{
                  1400: {
                    // spaceBetween: 70,
                    pagination: false
                  },
                  1024: {
                    pagination: false
                  }
                }}
              >
                {listSlide2.map(({ src, title, listInfo }) => (
                  <SwiperSlide className="swiper-slide-introductory-2">
                    {({ isActive }) => (
                      <Stack
                        sx={{
                          opacity: {
                            md: isActive ? 1 : 0.2,
                            xs: isActive ? 1 : 0.2
                          }
                        }}
                        width="100%"
                        height="100%"
                      >
                        <IntroductoryCard
                          src={src}
                          title={title}
                          listInfo={listInfo}
                        />
                      </Stack>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <Stack
              sx={{
                position: 'absolute',
                right: 120,
                top: '50%',
                transform: 'translate(0, -50%)',
                zIndex: 1000,
                display: {
                  md: 'block',
                  xs: 'none'
                }
              }}
            >
              <SwiperButton
                type="next"
                swiper={tab === 0 ? swiper : swiper2}
                disabled={
                  (tab === 0 && index === listSlide1.length - 1) ||
                  (tab === 1 && index2 === listSlide2.length - 1)
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const IntroductoryCard = (props) => {
  const classes = useStyles();
  const { src, title, listInfo } = props;
  return (
    <Stack flexDirection={{ md: 'row', xs: 'column' }} width="100%">
      <Stack width={{ md: '50%', xs: '100%' }}>
        <Stack sx={{ height: { md: '100%', xs: '270px' } }}>
          <img className={classes.img} src={src} alt="img" />
        </Stack>
      </Stack>
      <Stack
        flexDirection="column"
        width={{ md: '50%', xs: '100%' }}
        // p={{ md: '46px 12px 0 32px' }}
      >
        <Stack p={{ md: '46px 12px 0 32px', xs: '26px 0 0 0' }}>
          <Typography
            fontSize={{ md: 28, xs: 16 }}
            fontWeight={600}
            textAlign="left"
            mb={{ md: '36px', xs: '6px' }}
            letterSpacing="0.5px"
          >
            {title}
          </Typography>
          {listInfo.map(({ titleI, content }) => (
            <Stack
              flexDirection="row"
              alignItems="baseline"
              width="100%"
              mb={{ md: '10px', xs: '5px' }}
            >
              <Stack width={{ md: '50%', sm: '10%', xs: '20%' }}>
                <Typography
                  textAlign="left"
                  fontSize={{ md: 20, xs: 13 }}
                  color="#555"
                  fontWeight={600}
                  letterSpacing="0.5px"
                >
                  {titleI}
                </Typography>
              </Stack>
              <Stack width={{ md: '50%', xs: '80%' }}>
                <Typography
                  textAlign="left"
                  fontSize={{ md: 20, xs: 13 }}
                  color="#555"
                  letterSpacing="0.5px"
                  sx={{ wordBreak: 'keep-all' }}
                >
                  {content}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
