import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from '@pages/AboutUs';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Offresh2 from '@pages/Offesh2';
import PublicRoute from '@routes/PublicRoute';
import PrivateRoute from '@routes/PrivateRoute';
import { Login } from 'pages-client/Auth';
import MainLayout from '@layout/MainLayout';
import TotalOrderHistory from 'pages-client/TotalOrderHistory';
import { Account } from 'pages-client/Account';
import Layout from './layout';

function App() {
  useEffect(() => {
    AOS.init({ duration: 500 });
    AOS.refresh();
  });
  window.addEventListener('load', () => {
    AOS.init({ duration: 500 });
    AOS.refresh();
  });
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<AboutUs />} />
          <Route path="offresh" element={<Offresh2 />} />
        </Route>
        <Route path="" element={<PublicRoute />}>
          <Route path="/admin" element={<Login />} />
        </Route>
        <Route path="" element={<PrivateRoute />}>
          <Route path="/" element={<MainLayout />}>
            <Route
              path="/total-order-history"
              element={<TotalOrderHistory />}
            />
            <Route path="/account" element={<Account />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
