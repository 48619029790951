import { Hidden, Stack, Typography } from '@mui/material';
import React from 'react';

import img1 from 'images/sub2/sub2_sect4_img1.png';
import img2 from 'images/sub2/sub2_sect4_img2.png';
import img3 from 'images/sub2/sub2_sect4_img3.png';

import { makeStyles } from '@mui/styles';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './Service.css';
import { Pagination, Autoplay } from 'swiper';

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: '30px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      borderRadius: '15px',
      height: '270px',
      width: 'auto'
    }
  }
}));

export const Service = () => {
  return (
    <Stack
      bgcolor="#F5F5F5"
      sx={{
        width: '100%',
        margin: { md: '100px 0 0 0', xs: '40px 0 0 0' },
        pb: { md: '100px', xs: '40px' }
      }}
    >
      <Stack
        sx={{
          maxWidth: '1260px',
          margin: 'auto',
          width: '88%'
        }}
      >
        <Typography
          textAlign={{ md: 'center', xs: 'left' }}
          m={{ md: '92px', xs: '30px 0' }}
          fontSize={{ md: 36, xs: 20 }}
          fontWeight="bold"
        >
          복지 서비스 도입현황
        </Typography>
        <Hidden mdDown>
          <Stack flexDirection="row" alignItems="center" gap={{ md: 5 }}>
            {[
              { title: '하루만에 없어지는 상품', src: img1 },
              { title: '관제가 되지 않아 텅텅 빈 매대', src: img2 },
              { title: '공간과 어울리지 않는 디자인', src: img3 }
            ].map(({ title, src }, index) => (
              <div data-aos="fade-up">
                <ServiceCard title={title} src={src} index={index} />
              </div>
            ))}
          </Stack>
        </Hidden>
        <Hidden mdUp>
          <Swiper
            loop
            autoplay={{
              delay: 2000, // Thời gian trễ giữa các slide (ms)
              disableOnInteraction: false // Cho phép tự động phát khi người dùng tương tác
            }}
            centeredSlides
            spaceBetween={20}
            slidesPerView="auto"
            pagination={{
              clickable: true
            }}
            className="swiper-service-2"
            modules={[Pagination, Autoplay]}
            breakpoints={{
              1400: {
                spaceBetween: 70,
                pagination: false
              },
              1024: {
                pagination: false
              }
            }}
          >
            {[
              { title: '선호도가 떨어지는 상품', src: img1 },
              { title: '관제가 되지 않아 텅텅 빈 매대', src: img2 },
              { title: '공간과 어울리지 않는 디자인', src: img3 }
            ].map(({ title, src }, index) => (
              <SwiperSlide className="swiper-slide-service-2">
                <ServiceCard title={title} src={src} index={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Hidden>
      </Stack>
    </Stack>
  );
};

const ServiceCard = ({ src, title, index }) => {
  const classes = useStyles();
  return (
    <Stack
      flexDirection={{ md: 'column', xs: 'column-reverse' }}
      width="100%"
      bgcolor={{ md: 'none', xs: '#f5f5f5' }}
      pb={{ md: '0', xs: '30px' }}
    >
      <Stack sx={{ height: { md: '100%', xs: '270px' } }}>
        <img className={classes.img} src={src} alt="img" />
      </Stack>
      <Stack>
        <Typography
          color="primary"
          sx={{ fontSize: { md: 20, xs: 13 } }}
          fontWeight={600}
          m={{ md: '35px 0 10px 0' }}
          textAlign={{ md: 'none', xs: 'left' }}
        >
          Point 0{index + 1}
        </Typography>
        <Typography
          color="black"
          sx={{
            fontSize: { md: 28, xs: 16 },
            fontWeight: 'bold',
            letterSpacing: '0.5px'
          }}
          m={{ xs: '5px 0 20px 0' }}
          textAlign={{ md: 'none', xs: 'left' }}
        >
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};
