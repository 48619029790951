import React from 'react';
import { Stack } from '@mui/material';

const SearchBar = ({ children }) => {
  return (
    <Stack
      sx={{
        p: '20px',
        borderRadius: '12px',
        bgcolor: '#F8F8F8',
        flexWrap: 'wrap'
      }}
      direction="row"
      gap={1.5}
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

export default SearchBar;
