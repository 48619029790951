import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@components/buttons/LoadingButton';
import { useTheme } from '@mui/styles';

export default function Modal({
  open,
  setOpen,
  title = '서비스 문의',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hideDialogButton = true,
  loading,
  setLoading,
  sx,
  disabled,
  onClose,
  ...rest
}) {
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    if (loading) setLoading(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: { md: '15px', xs: '0px' },
          maxWidth: { md: '960px', xs: '100%' },
          width: { md: '960px', xs: '100%' },
          ...sx
        }
      }}
      fullScreen={isSmallScreen}
      {...rest}
    >
      {title && (
        <DialogTitle sx={{ p: '0px !important' }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: '100%',
              m: { md: '20px 0', xs: '8px 0' },
              p: '0 20px'
            }}
          >
            <IconButton
              sx={{ visibility: 'hidden' }}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ fontSize: { md: '26px', xs: 16 }, fontWeight: 500 }}
            >
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
      )}
      {title && <Divider />}
      {children && (
        <DialogContent
          sx={{
            // p: 0,
            p: { md: '60px 60px 80px 60px', xs: '40px 20px 50px 20px' },
            ...sx?.dialogContent
            // overflow: 'hidden'
          }}
        >
          {children}
        </DialogContent>
      )}
      {!hideDialogButton && (
        <DialogActions sx={{ justifyContent: 'center', mt: '30px', p: 0 }}>
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: '44px',
                minWidth: '110px',
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || 'CANCEL'}
            </Button>
          )}
          <LoadingButton
            onClick={onOk || handleClose}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px',
              flex: isAlert ? 1 : 'unset'
            }}
            loading={loading}
            disabled={disabled}
          >
            {okTitle || 'OK'}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
