import React from 'react';
import GroupTableCustomize from '@components/MyTable/GroupTableCustomize';
import { useTranslation } from 'react-i18next';
import { columnDetailPayment } from '../enhance';

const PaymentDetails = ({ items }) => {
  const { t } = useTranslation();
  const configColumn = columnDetailPayment(t);
  return <GroupTableCustomize hover columns={configColumn} data={items} />;
};

export default PaymentDetails;
