import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { ToastContainer } from 'react-toastify';

import { Backdrop, CircularProgress, DialogContentText } from '@mui/material';
import MyDialogLP from '@components/dialogs/MyDialog';
import MyDialog from '@components/MyDialog';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import 'react-toastify/dist/ReactToastify.css';
// import 'simplebar-react/dist/simplebar.min.css';

// import 'yet-another-react-lightbox/styles.css';
// import 'yet-another-react-lightbox/plugins/captions.css';
// import 'yet-another-react-lightbox/plugins/counter.css';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';

import './App.css';
import './index.css';

const Globals = () => {
  const { loading, error, errorLP, info, onOk } = useSelector(
    (state) => state.app
  );
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (error || info) setOpenDialog(true);
  }, [error, info]);

  return (
    <>
      {/* <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      /> */}
      {info && (
        <MyDialog
          open={openDialog}
          setOpen={() => {
            setOpenDialog(false);
            onOk();
          }}
          isAlert
          {...info}
        >
          {info?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {info?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      {errorLP && (
        <MyDialogLP
          open={openDialog}
          setOpen={setOpenDialog}
          isAlert
          {...errorLP}
        >
          {errorLP?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {errorLP?.content}
            </DialogContentText>
          )}
        </MyDialogLP>
      )}
      {error && (
        <MyDialog open={openDialog} setOpen={setOpenDialog} isAlert {...error}>
          {error?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {error?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      <Backdrop
        sx={{
          color: 'common.white',
          zIndex: (theme) => theme.zIndex.modal + 1
        }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CircularProgress
        sx={{
          display: loading ? 'block' : 'none',
          position: 'fixed',
          bottom: '10px',
          left: '15px',
          zIndex: 1000
        }}
      />
    </>
  );
};

export default Globals;
